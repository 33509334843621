<template>
  <div style="position: relative; height: 100%;">
    <loadAnimation v-if="loadingMap"/>
    <div class="MyToolTip"></div>
    
    <svg v-if="!loadingMap" id="svgC" viewBox="0 0 1024 768" style="height: 100%; width: 100%;">
      <g id="gMap">
        <image 
          :width="1024" 
          :height="768" 
          :href="preview ? ($root.UrlImg + preview) : require('../../assets/sorry.webp')" 
          id="imgbackground">
        </image>
      </g>
      <g id="MapPreviwX">
        <g v-for="(Map, index) in MapSvg"
          :key="index"
          :id="'section-' + Map.id_section"
          @mouseenter="tooltipInfo($event, Map, true)" 
          @mouseleave="tooltipInfo($event, Map, false)">

          <polygon 
            :points="PaintFigures(Map.shapes)" 
            :key="'polygon-' + index" 
            :fill="sectionHover == Map.id_section ? 'orange' : Map.color">
          </polygon>

          <circle
            v-for="(c, index2) in Map.circles"
            :cx="c.cx"
            :cy="c.cy"
            :r="c.r"
            :fill="sectionHover == Map.id_section ? 'orange' : Map.color"
            :key="'circle-' + index + '_' + index2"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import $ from "jquery";
import loadAnimation from './loadinAnimation.vue'

export default {
  name:"MapPreview",
  props: ["preview", "MapSvg", "sectionHover", "loadingMap"],
  components: {
    loadAnimation
  },
  data() {
    return {
      
    }
  },
  methods: {
    PaintFigures(polygon) {
      if (polygon) {
        return polygon
          .map((point) => {
            return point.x + "," + point.y;
          })
          .join(" ");
      }
    },
    tooltipInfo: function (event, section, flav) {
      let style = "padding:2px 8px 2px 8px;";
      let style1 = "padding: 9px 8px 2px 8px;";

      let cardContent =`
        <div style="background: var(--color-primary); height: 15px;"></div> 
        <table style="margin-top:5px;" class="px-4" > 
          <tr>
            <td colspan="3" style="font-size:16px;">
              <strong>&nbsp;ÁREA:&nbsp; ${section.area} </strong>
            </td></tr> 
          <tr> 
          <td style="${style1} font-size:11px; color:var(--color-primary);"><strong>SECTION</strong></td> 
          <td style="${style1} font-size:11px; color:var(--color-primary); border-left:1px solid #CCC;">
            <strong>MÁXIMO</strong></td> 
          </tr> 
          <tr> 
            <td style="${style}"> ${section.section} </td> 
            <td style="${style} border-left:1px solid #CCC;">
              ${(section.max == null ? "Sin asignar" : parseInt(section.max))}
            </td> 
          </tr> 
        </table> 
          <div style="background: #e8e8e8; height: 6px; margin-top:5px;"></div> 
          <div style="${style1}"> 
        <table class="pa-0 px-1 py-1">
          <tr> 
            <td> 
              <div style="width:20px; height: 20px; border-radius:10px; background:var(--color-primary);"></div> 
            </td> 
            <td class="px-3"> ${(section.showseat === 1 ? "CON Asientos" : "SIN Asientos")} </td>
          </tr>
        </table> 
        </div>`;
        
      this.tooltipStatus(flav, cardContent, event); 
    },
    tooltipStatus(showTooltip, tooltipBody, event) {
      if(showTooltip) {
        $(".MyToolTip").html(tooltipBody);

        let top     = event.y - 400
        let left    = event.x + 5
        let maxTop  =  $('.mapSvg').outerHeight() - $('.MyToolTip').outerHeight() - 1
        let maxLeft = $('.mapSvg').outerWidth() - $('.MyToolTip').outerWidth() - 10
        
        let minTop = 0
        top = top < minTop ? minTop : top
        top = top > maxTop ? maxTop : top
        left = left < minTop ? minTop : left
        left = left > maxLeft ?  (event.x - $('.MyToolTip').outerWidth() - 10) : left

        
        $(".MyToolTip").css({
          display: "block",
          left: left + 'px',
          top: top + 'px',
        });
        return false;
      }
      
      $(".MyToolTip").css({ display: "none" });
    },
  }
}
</script>

<style scoped>
#imgbackground {
  border: 1px solid red !important;
}
.MyToolTip {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  background: white;
  border-radius: 6px;
  border: 1px solid #ccc;
  display: none;
}
</style>