<template>
  <v-card flat class="mt-0 grey lighten-5 pa-5">
    <v-row>
      <v-col cols="12">
        <div class="mt-n5">
          <div class="Mytab mx-auto">
            <div
              v-for="(categoryX, index) in subcategories"
              class="tab"
              @click="tab === index || changetab(index)"
              :class="tab === index ? 'active' : ''" 
              :key="index">
              {{ categoryX }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-container class="d-flex mb-6 flex-column">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="my-2 pa-2 ml-auto"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            @click="newSubcateDialog = true"
          > 
            AGREGAR
          </v-btn>
        </template>
        <span>Agregar nueva subcategoría</span>
      </v-tooltip>
      <newSubCategories
      :subcategorie="subcategories[tab]"
      />
      
      <div v-if="vEntorno">
        <v-data-table
          :headers="SubcategoriesHeader"
          :items="vEntorno.Subevents"
          :search="search"
          class="row-pointer"
          @click:row="openSubcategoryGroup"
        >
        <template v-slot:item.title="{ item }">
          <tr>
            <td>{{ item.title[leng] }}</td>
          </tr>
        </template>

        <template v-slot:item.description="{ item }">
          <tr>
            <td>{{ item.description[leng] }}</td>
          </tr>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="d-flex align-center justify-space-around">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs"
                    @click="subcategoryFlag = false;"
                    v-on="on">                  
                  <v-switch 
                      @change="categoryStatus(item)"
                      v-model="item.status">
                  </v-switch>
                </div>
              </template>
              <span>{{ !item.status ? 'Activar Subcategoría' : 'Desactivar Subcategoría' }}</span>
            </v-tooltip>

            <v-btn icon large @click="deleteSubcategory(item)">
              <v-icon color="grey darken-3"> mdi-delete </v-icon>
            </v-btn>
          </div>
        </template>

        </v-data-table>
      </div>
    </v-container>
    <subcategorieConfig 
      v-if="subcateDialog"
      :subcateDialog="subcateDialog" 
      :subcategorie="subcategories[tab]"
      :index="index"
      @closeSubcategorieConfig="closeSubcateDialog"/>

      <newSubCategories
      v-if="newSubcateDialog"
      :subcategorie="subcategories[tab]"
      :showDialog="newSubcateDialog"
      :subcateType="tab"
      @cancelCreation="newSubcateDialog = false"
      @rechargeSubcate="load"
      />
  </v-card>
</template>

<script>
import subcategorieConfig from './subcategoriesDialog.vue';
import newSubCategories from './newSubCategories.vue';
import $ from "jquery";

//Venta de entrada libre
export default {
  name: "EventType3",
  components: {
    subcategorieConfig,
    newSubCategories
  },
  data() {
    return {
      leng: 'ES',
      ListEspecifShow:true,
      defCountry: { icon: "" },
      options: [
        { icon: "" },
        { icon: "mdi-clock" },
        { icon: "mdi-calendar-check" },
        { icon: "mdi-alert-circle" },
      ],
      item_especifica: {info:'',icon:null,index:null},
      vEntorno: null,
      textRich: null,
      descrip: null,
      subcateDialog: false,
      newSubcateDialog: false,

      tab: 0,
      tabs: [],
      length: 3,
      panel: 0,

      SubcategoriesHeader: [
        {
          text: "Nombre de subcategoria",
          align: "start",
          value: "title",
          class: "grey darken-4 grey--text text--lighten-5 overline"
        },
        { text: "Descripción de la subcategoría", 
          value: "description", 
          align: "start",
          class: "grey darken-4 grey--text text--lighten-5 overline"
        },
        {
          text: "Acciones", 
          value: "actions", 
          align: "center",
          class: "grey darken-4 grey--text text--lighten-5 overline"
        },
      ],

      subcategories: [
        // "DESCRIPCIÓN GENERAL",
        "ACCESO GENERAL",
        "ACTIVIDADES",
        "COMBINACIONES"
      ],

      tickets: [],
      search: null,
      information: null,
      index: 0,
      subcategoryFlag: true
    };
  },
  props: ["MyEvent","vtab"],
  mounted() {
    this.vEntorno = this.$vEntorno;
    this.load(this.MyEvent);
  },
  watch: {
    vtab(val) {
      switch (val) {
        case 0:
          this.leng= 'ES'
        break;
        case 1:
          this.leng= 'EN'
        break;
      }
    },
  },
  methods: {
    categoryStatus(subcategory) {
      this.subcategoryFlag = false;
      let statusTxt = "activar"
      let text = "Al activar, se podrá visualizar en la Webshop"
      if(subcategory.status === 1) {
        statusTxt = "desactivar"
        text = "Al desactivar, ya no se podrá visualizar en la Webshop"
      }
      this.$root.$swal({
        title: `¿Desea ${statusTxt} la subcategoría?`,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, estoy seguro!'
      })
      .then( response => {
        if(response.isConfirmed) {  
          const newStatus = subcategory.status ? 1 : 0;
          this.changeStatus(subcategory, newStatus)
        } else {
          subcategory.status = subcategory.status ? 0 : 1;
        }
      });
    },
    deleteSubcategory(subcategory) {
      this.subcategoryFlag = false;
      const index =  this.vEntorno.Subevents.indexOf(subcategory)
      if(index === -1) return;
      
      this.$root.$swal({
        title: '¿Desea eliminar esta subcategoría?',
        text: "Una vez borrada la subcategoría no se visualizar en la Webshop",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, estoy seguro(a)!'
      })
      .then( response => {
        if(response.isConfirmed) {
          this.changeStatus(subcategory, 2, index)
        }
      });
    },
    changeStatus(subcate, newStatus, deleteIndex) {
      const prevStatus = newStatus ? 0 : 1
      
      subcate.status = newStatus;
      this.$root
      .post("/Admin/updateSubcategorie", { 
        id_cataloge: subcate.id_cataloge,
        typeUpdate: 3,
        newStatus
      })
      .then((response) => {
        const info = response.data;        
        if (info.success === "TRUE") {
          if(newStatus === 2) this.vEntorno.Subevents.splice(deleteIndex, 1);
          this.$root.swalAlert("success", "Subcategoría modificada");
        } else {          
          this.$root.swalAlert("error", info.Msg);
          subcate.status = prevStatus
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", e.message);
      });
    },
    closeSubcateDialog() {
      this.$store.dispatch('events/resetCataloge_A');
      this.subcateDialog = false;
    },
    openSubcategoryGroup(item, { index }) {
      this.$store.dispatch('events/setCurrentCataloge_A', item.id_cataloge);
      
      if(this.subcategoryFlag === false) {
        this.subcategoryFlag = !this.subcategoryFlag;
        return;
      } 
      this.index = index;
      this.subcateDialog = true;

      setTimeout(() => {
        $(".SubevetsTop").scrollTop(0);
      }, 10);
    },
    load(item) {
      this.$set(this.$vEntorno, "Subevents", [ ]);

      this.$root
      .post("/Admin/GetSubCategorias", { event: item })
      .then((response) => {
        const info = response.data;
        
        if (info.success === "TRUE") {
          this.information = { 
            category: info.category 
          };          
          this.getSubEventData();
        } else {
          this.information = { 
            category: null
          };
          this.$set(this.$vEntorno, "Subevents", [ ]);
        }
      });
    },
    getSubEventData() {
      if(!this.information.category){
        this.$set(this.$vEntorno, "Subevents", [ ]);
        return false
      }
      let resul = this.information.category.find(
        (elem) => elem.id_category === this.tab //- 1
      );
      if (resul) {
        for (var x = 0; x < resul.cate.length; x++) {
          if (resul.cate[x].info) {
            if (typeof resul.cate[x].info != "object") {
              resul.cate[x].info = JSON.parse(resul.cate[x].info);
            }
          }
        }
        this.$set(this.$vEntorno, "Subevents", resul.cate);
        this.ListEspecifShow = true;
      } else {
        this.ListEspecifShow = false
        this.$set(this.$vEntorno, "Subevents", []);
      }
    },
    changetab(tab) {
      this.tab = tab;
      this.getSubEventData();
    },
    
  },
};
</script>

<style scoped>
.titleTickets{
  position: relative;
  margin-top: -30px;
  left: calc(100% - 122px);
}

.MyToolBar {
  position: fixed;
  width: 100%;
  height: 76px !important;
  z-index: 1;
  padding: 10px;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.pointman:hover {
  cursor: pointer;
}

.MyTitle {
  text-align: left;
  font-weight: 800;
  color: #6e6e6e;
}
.txtColor {
  padding: 10px;
  text-align: center;
  color: red;
  font-size: 14px;
}
.BtnNewGalley {
  display: flex;
  align-content: space-between;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}
.Mytab {
  font-size: 12px;
  display: flex;
  width: 100%;
  border-bottom: 2px solid #252525;
}
.Mytab .tab {
  padding: 8px 10px;
  border-radius: 5px 5px 0px 0px;
  font-weight: bold;
  letter-spacing: 2px;
}
.Mytab .tab:hover:not(.active) {
  background: rgb(73, 69, 69);
  color: black;
  cursor: pointer;
  color: white;
}
.Mytab .active {
  background: #252525;
  color: white;
}
.BtnImg {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  zoom: 80%;
}
@media (max-width: 1301px) {
  .Mytab {
    margin-left: 30px !important;
    margin-right: 30px !important;
    width: auto;
  }
}
@media only screen and (max-width: 700px) {
  .Mytab {
    display: flex;
    flex-direction: column;
    margin-left: 15px !important;
    margin-right: 15px !important;
    border-bottom: 0px;
    border-left: 2px solid #252525;
  }

  .Mytab .tab {
    border-radius: 0px 5px 5px 0px;
  }
}
</style>