<template>
  <v-container fluid>

    <calendarToolPreview ref="CalendarToolX" 
      :dates="datesPrepared"
      @discardDate="cancelDate"/>

    <dateCreationDialog 
      v-if="addDatesDialog"
      :showDatesDialog="addDatesDialog"
      :id_event="$store.state.events['eventSelected']['id_event']"
      :event_config="$store.state.events['eventSelected']['event_config']"
      :max="maxCapacity"
      @cancelProcess="cancelDates"
      @addNewDates="prepareDates"/>
  </v-container>
</template>

<script>
import dateCreationDialog from "../events/createDatesDialog.vue"
import calendarToolPreview from "./calendarTool.vue"

export default {
  name: "dateSettingsSection",
  props: ["maxCapacity"],
  components: {
    dateCreationDialog,
    calendarToolPreview
  },
  data() {
    return {
      addDatesDialog: false,
      datesPrepared: [],
      currentDate: ''
    }
  },
  mounted() {
    this.getCurrentDate()
  },
  methods: {
    cancelDates() {
      this.addDatesDialog = false
    },
    addNewDates() {
      this.addDatesDialog = true
    },
    prepareDates( dates, resetFlag ) {
      const fechaActual = new Date(this.currentDate);
      if(resetFlag) this.datesPrepared = []

      dates.forEach((dateX) => {
        let presaleDate = new Date(dateX.presale);
        this.datesPrepared = [
          ...this.datesPrepared,
          {
            ...dateX,
            name: "Evento",
            end: null,
            color: dateX.cancel ? "blue-grey lighten-2" : fechaActual >= presaleDate ? "indigo accent-3" : "indigo lighten-1",
            timed: true
          }
        ] 
      })
      if(!resetFlag) this.addDatesDialog = false
    },
    setCaledarToday() {
      this.$refs.CalendarToolX.setToday()
    },
    getCurrentDate() {
      const date = new Date();
      
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      
      const hour = '12:00:00.000Z';
      this.currentDate = `${year}-${month}-${day}T${hour}`;
    },
    cancelDate(eventd) {
      this.$root
      .post("/Admin/cancelDate", { 
        id_eventd: eventd.id_eventd,
        id_event: eventd.id_event
      })
      .then((response) => {
        const info = response.data;
        if (info.success === "TRUE") {
          eventd.cancel = true
          eventd.color = "blue-grey lighten-2"
          this.$root.swalAlert("success", "Fecha cancelada");
        } else {
          this.$root.swalAlert("error", "", info.Msg);
        }
      })
      .catch((e) => {
        this.$root.swalAlert("error", "Error en cancelación", e.message);
      });
    }
  }
}
</script>