<template>
  <v-card flat style="margin-top: 10px">
    <v-row>
      <v-col cols="12">
        <div class="mt-n5">
          <div class="Mytab mx-auto">
            <div
              class="tab"
              v-for="(item, index) in configTabs"
              @click="tab = index"
              :class="tab === index ? 'active' : ''"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-container class="my-1 d-flex mb-6 flex-column">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="newSubcateDialog = true"
            class="my-2 pa-2 ml-auto"
            color="grey darken-4"
            dark
            v-bind="attrs"
            v-on="on"
            v-show="tab === 0 && vEntorno.Subevents.length === 0"
          >
            configurar
          </v-btn>
        </template>
        <span>Agregar configuración de boletos</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="my-2 pa-2 ml-auto"
            color="grey darken-4"
            dark
            v-bind="attrs"
            v-on="on"
            @click="addDatesDialog = true"
            v-show="tab === 1"
          >
            <v-icon> mdi-plus </v-icon> AGREGAR
          </v-btn>
        </template>
        <span>Crear nuevas fechas</span>
      </v-tooltip>

      <div v-if="vEntorno && tab === 0">
        <v-data-table
          :headers="subcategoriesHeader"
          :items="vEntorno.Subevents"
          :search="search"
          class="row-pointer"
          @click:row="openSubcategoryGroup"
        >
          <template v-slot:item.title="{ item }">
            <tr>
              <td>{{ item.title[leng] }}</td>
            </tr>
          </template>

          <template v-slot:item.description="{ item }">
            <tr>
              <td>{{ item.description[leng] }}</td>
            </tr>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <div class="d-flex align-center justify-space-around" @click="subcategoryFlag = false;">
              <v-switch v-model="item.status" @change="categoryStatus(item)">
              </v-switch>
            </div>
          </template>
        </v-data-table>
      </div>
      <div v-if="vEntorno && tab === 1">
        <calendarTool :dates="datesPrepared" @discardDate="cancelDate"/>
      </div>
    </v-container>

    <subcategorieConfig
      v-if="dialogSubevents"
      :subcateDialog="dialogSubevents"
      :subcategorie="configTabs[tab]"
      :index="index"
      @closeSubcategorieConfig="closeSubcateDialog"
    />

    <createDatesDialog
      v-if="addDatesDialog"
      :id_event="MyEvent.id_event"
      :showDatesDialog="addDatesDialog"
      @cancelProcess="cancelDates"
      @addNewDates="addNewDates"
    />

    <newSubCategories
      v-if="newSubcateDialog"
      :subcategorie="'Evento con cupo limitado'"
      :showDialog="newSubcateDialog"
      :subcateType="0"
      @cancelCreation="newSubcateDialog = false"
      @rechargeSubcate="loadTicketConfiguration"
    />
  </v-card>
</template>

<script>
import createDatesDialog from "./createDatesDialog.vue";
import subcategorieConfig from "./subcategoriesDialog.vue";
import newSubCategories from "./newSubCategories.vue";
import calendarTool from "../Tools/calendarTool.vue"
import $ from "jquery";

//Venta tipo bitacora
export default {
  name: "EventType4",
  props: ["MyEvent", "vtab"],
  components: {
    createDatesDialog,
    subcategorieConfig,
    newSubCategories,
    calendarTool
  },
  data() {
    return {
      configTabs: ["CONFIGURACIÓN DE BOLETOS", "CONFIGURACIÓN DE FECHAS"],
      leng: "ES",
      ListEspecifShow: true,
      defCountry: { icon: "" },
      options: [
        { icon: "" },
        { icon: "mdi-clock" },
        { icon: "mdi-calendar-check" },
        { icon: "mdi-alert-circle" },
      ],
      item_especifica: { info: "", icon: null, index: null },
      vEntorno: null,
      textRich: null,
      descrip: null,
      dialogSubevents: false,
      newSubcateDialog: false,

      tab: 0,
      tabs: [],
      length: 3,
      panel: 0,

      subcategoriesHeader: [
        {
          text: "Nombre de subcategoria",
          align: "start",
          value: "title",
        },
        {
          text: "Descripción de la subcategoría",
          value: "description",
          align: "rigth",
        },
        {
          text: "Activo",
          value: "active",
          align: "center",
        },
      ],

      eventDatesHeader: [
        {
          text: "Fecha del evento",
          align: "start",
          value: "date_event",
        },
        { text: "Fecha de Preventa", value: "presale", align: "start" },
        { text: "Hora del Evento", value: "time_event", align: "center" },
        { text: "Limite de personas", value: "max", align: "center" },
        // {
        //   text: "Eliminar fecha",
        //   value: "deleteDate",
        //   sortable: false,
        //   align: "center",
        // }
      ],

      tickets: [],
      search: null,
      information: {
        category: [],
        dates: [],
      },
      index: 0,
      addDatesDialog: false,
      subcategoryFlag: false,
      datesPrepared : []
    };
  },
  watch: {
    vtab(val) {
      switch (val) {
        case 0:
          this.leng = "ES";
          break;
        case 1:
          this.leng = "EN";
          break;
      }
    },
    tab: function (val) {
      if(val === 1) this.loadEventDates(this.MyEvent);
    }
  },
  created() {
    this.vEntorno = this.$vEntorno;
    this.information = {
      category: [],
      dates: [],
    };
    this.$set(this.$vEntorno, "Subevents", []);
  },
  mounted() {
    this.vEntorno = this.$vEntorno;
    this.information = {
      category: [],
      dates: [],
    };
    this.$set(this.$vEntorno, "Subevents", []);
    this.loadTicketConfiguration(this.MyEvent);
  },
  methods: {
    cancelDates() {
      this.addDatesDialog = false;
    },
    Setitem(item) {
      this.vEntorno.Subevents[this.index].info.ES[
        this.item_especifica.index
      ].icon = this.defCountry.icon;
      this.vEntorno.Subevents[this.index].info.ES[
        this.item_especifica.index
      ].info = this.item_especifica.info;

      this.$set(this.$vEntorno, "Subevents", this.vEntorno.Subevents);
    },
    closeSubcateDialog() {
      this.$store.dispatch("events/resetCataloge_A");
      this.dialogSubevents = false;
    },
    openSubcategoryGroup(item, { index }) {
      if(this.subcategoryFlag === false) {
        this.subcategoryFlag = !this.subcategoryFlag;
        return;
      } 
        this.$store.dispatch("events/setCurrentCataloge_A", item.id_cataloge);
        this.index = index;

        this.dialogSubevents = true;

        setTimeout(() => {
         $(".SubevetsTop").scrollTop(0);
        }, 10);
    },
    loadTicketConfiguration(item) {
      this.$set(this.$vEntorno, "Subevents", []);

      this.$root
        .post("/Admin/GetSubCategorias", { event: item })
        .then((response) => {
          const info = response.data;
          if (info.success === "TRUE") {
            this.information.category = info.category;

            this.getSubEventData();
          } else {
            this.information.category = [];
            this.$set(this.$vEntorno, "Subevents", []);
            this.prepareDates([], true)
          }
        })
        .catch((e) => {
          this.$root.swalAlert("error", "Error de carga", e.message);
        });
    },
    loadEventDates(myEvent) {
      this.prepareDates([], true)

      this.$root
        .post("/Admin/getEventDates", { event: myEvent })
        .then((response) => {
          const info = response.data;
          if (info.success === "TRUE") {
            this.prepareDates(info.dates, true)
          } else {
            this.prepareDates([], true)
          }
        })
        .catch((e) => {
          this.$root.swalAlert("error", "Error de carga", e.message);
        });
    },
    getSubEventData() {
      if (!this.information.category) {
        this.$set(this.$vEntorno, "Subevents", []);
        return false;
      }
      let cate = this.information.category[0].cate;

      if (cate) {
        for (let x = 0; x < cate.length; x++) {
          if (cate[x].info) {
            if (typeof cate[x].info != "object") {
              cate[x].info = JSON.parse(cate[x].info);
            }
          }
        }

        this.$set(this.$vEntorno, "Subevents", cate);
        this.ListEspecifShow = true;
      } else {
        this.ListEspecifShow = false;
        this.$set(this.$vEntorno, "Subevents", []);
      }
    },
    addNewDates(dates) {
      this.prepareDates(dates)
      this.cancelDates();
    },
    fixDate(date) {
      let x = date;
      if (x) {
        x = new Date(x);
        const day = x.getUTCDate();
        const month = x.getUTCMonth() + 1;
        const year = x.getUTCFullYear();
        const opcion = {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        x = new Intl.DateTimeFormat("es-ES", opcion).format(
          new Date(year, month - 1, day)
        );
      }
      return x;
    },
    categoryStatus(subcategory) {
      let statusTxt = "activar"
      let text = "Al activar, se podrá visualizar en la Webshop"
      if(subcategory.status === 1) {
        statusTxt = "desactivar"
        text = "Al desactivar, ya no se podrá visualizar en la Webshop"
      }
      this.$root.$swal({
        title: `¿Desea ${statusTxt} la subcategoría?`,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, estoy seguro!'
      })
      .then( response => {
        if(response.isConfirmed) {
          const newStatus = subcategory.status ? 1 : 0;
          this.changeStatus(subcategory, newStatus)
        }
        else {
          subcategory.status = subcategory.status ? 0 : 1;
        }
      });
    },
    changeStatus(subcate, newStatus) {
      const prevStatus = newStatus ? 0 : 1
      subcate.status = newStatus;
      this.$root
      .post("/Admin/updateSubcategorie", { 
        id_cataloge: subcate.id_cataloge,
        typeUpdate: 3,
        newStatus
      })
      .then((response) => {
        const info = response.data;        
        if (info.success === "TRUE") {
          this.$root.swalAlert("success", "Subcategoría modificada");
        } else {          
          this.$root.swalAlert("error", info.Msg);
          subcate.status = prevStatus
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", e.message);
      });
    },
    prepareDates( dates, resetFlag ) {
      if(resetFlag) this.datesPrepared = []

      dates.forEach((dateX) => {
        this.datesPrepared = [
          ...this.datesPrepared,
          {
            ...dateX,
            name: `Evento ${dateX.id_eventd_varchar}`,
            end: null,
            color: dateX.cancel ? "blue-grey lighten-2" : "indigo lighten-1",
            timed: true
          }
        ] 
      })
    },
    cancelDate(eventd) {
      this.$root
      .post("/Admin/cancelDate", { 
        id_eventd: eventd.id_eventd,
        id_event: eventd.id_event
      })
      .then((response) => {
        const info = response.data;
        if (info.success === "TRUE") {
          eventd.cancel = true
          eventd.color = "blue-grey lighten-2"
          this.$root.swalAlert("success", "Fecha cancelada");
        } else {
          this.$root.swalAlert("error", "", info.Msg);
        }
      })
      .catch((e) => {
        this.$root.swalAlert("error", "Error en cancelación", e.message);
      });
    }
  },
};
</script>

<style scoped>
.capitalized-style::first-letter {
  text-transform: uppercase !important;
}
.MyToolMax {
  max-width: 1200px !important;
}

.MyToolBar {
  position: fixed;
  width: 100%;
  height: 76px !important;
  z-index: 1;
  padding: 10px;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.pointman:hover {
  cursor: pointer;
}

.MyTitle {
  text-align: left;
  font-weight: 800;
  color: #6e6e6e;
}
.txtColor {
  padding: 10px;
  text-align: center;
  color: red;
  font-size: 14px;
}
.BtnNewGalley {
  display: flex;
  align-content: space-between;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}
.Mytab {
  font-size: 12px;
  display: flex;
  width: 100%;
  border-bottom: 2px solid #252525;
}
.Mytab .tab {
  padding: 8px 10px;
  border-radius: 5px 5px 0px 0px;
  font-weight: bold;
  letter-spacing: 2px;
}
.Mytab .tab:hover:not(.active) {
  background: rgb(73, 69, 69);
  color: black;
  cursor: pointer;
  color: white;
}
.Mytab .active {
  background: #252525;
  color: white;
}
.BtnImg {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  zoom: 80%;
}
@media (max-width: 1301px) {
  .Mytab {
    margin-left: 30px !important;
    margin-right: 30px !important;
    width: auto;
  }
}
@media only screen and (max-width: 700px) {
  .Mytab {
    display: flex;
    flex-direction: column;
    margin-left: 15px !important;
    margin-right: 15px !important;
    border-bottom: 0px;
    border-left: 2px solid #252525;
  }

  .Mytab .tab {
    border-radius: 0px 5px 5px 0px;
  }
}
</style>
