<template>
  <div>
    <v-form ref="AvailableTicketsMapTableForm">
      <v-data-table
        :headers="headers"
        :items="sectionsCopy"
        :items-per-page="5"
        no-data-text="No hay secciones habilitadas"
        class="elevation-0 transparent mt-3">

        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="(item) in items"
              :key="item.id_section"
              @mouseover="hoverSection(item)"
              @mouseleave="hoverSection(false)">
              <td>
                {{ item.name }}
              </td>
              <td class="pa-0 text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      :color="item.showseat !== 1 ? 'amber lighten-3' : 'light-blue lighten-3'" >
                      <v-icon
                        color="light-blue darken-3"
                        dark
                        v-bind="attrs"
                        v-on="on">
                        {{ item.showseat === 1 ? 'mdi-seat' : 'mdi-human-queue' }}
                      </v-icon>
                    </v-chip>
                  </template>
                  <span>{{ item.showseat === 1 ? 'Sección con asientos' : 'Sección sin asientos' }}</span>
                </v-tooltip>
              </td>
              <td style="max-width: 150px;">
                <v-select
                  dense
                  v-model="item.id_ticket"
                  :disabled="lockMap"
                  :items="availableTickets"
                  :item-text="({ alias, price }) => `${alias} - $${price}`"
                  item-value="id_ticket"
                  hide-selected
                  :rules="[ rules.ticketRequired ]"
                  no-data-text="Favor de contactar a soporte para activar boletos."
                  placeholder="Selección Boleto"
                ></v-select>
              </td>
              <td style="max-width: 90px;">
                <v-text-field
                  v-model="item.max_seat"
                  dense
                  type="number"
                  :disabled="item.showseat === 1 || lockMap"
                  :rules="[ rules.maxRequired ]"
                  @change="checkValue(item)"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
        
        <template v-slot:header.max="{ header }">
          {{ header.text }} <v-icon>mdi-account-supervisor</v-icon>
        </template>
        
      </v-data-table>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "AvailableTicketsMapTable",
  props: ["sections", "availableTickets", "lockMap"],
  data() {
    return {
      headers: [
        { text: 'Sección', align: 'start', value: 'name' },
        { text: 'Tipo', value: 'showseat', align: 'center' },
        { text: 'Boleto', value: 'id_ticket', align: 'center' },
        { text: 'Máximo', value: 'max_seat', align: 'center' }
      ],

      rules: {
        maxRequired: v => v !== null && v >= 0 || 'Cantidad de personas inválida',
        ticketRequired: v => v !== null || 'Seleccione boleto para la sección!'
      },

      selectedSection: false,
      sectionsCopy: []
    } 
  },
  mounted() {
    this.sectionsCopy = [...this.sections]
  },
  methods: {
    hoverSection(item) {
      if(item) {
        const { id_section } = item
        this.selectedSection = id_section
      } 
      else this.selectedSection = false
      this.$emit("hoverSection", this.selectedSection)
    },
    confirmTicketRelation() {
      if(this.$refs.AvailableTicketsMapTableForm.validate()) {
        this.$emit('verifyTickets', this.sectionsCopy)
      }
    },
    resetTableValidation() {
      this.sectionsCopy = [...this.sections]
      this.$refs.AvailableTicketsMapTableForm.resetValidation()
    },
    resetTable() {
      this.sectionsCopy = []
    },
    checkValue({ id_section, max_seat }) {
      const index = this.sectionsCopy.findIndex(element => element.id_section == id_section);
      
      if(index !== -1) {

        if(isNaN(parseInt(max_seat))) {
          this.sectionsCopy[index].max_seat = parseInt(0)
          max_seat = 0
        } 
        else {
          this.sectionsCopy[index].max_seat = parseInt(this.sectionsCopy[index].max_seat)
          max_seat = parseInt(this.sectionsCopy[index].max_seat)
        }
      }
    }
  }
}
</script>

<style scoped>
.my-face:before {
  content: material-icons-content('face');
}
</style>