<template>
  <v-dialog v-model="showDatesDialog" scrollable persistent max-width="1000">
    <v-card>
      <v-card-title class="indigo darken-4 white--text text-uppercase"
        >Añadir nuevas fechas</v-card-title
      >
      <v-card-text style="height: 950px" class="pa-0 px-5">
        <v-container class="mx-auto pa-0 ma-0 px-5">
          <v-form ref="dateForm">
            <v-row>
              <v-col
                class="mt-3 d-flex justify-center"
                cols="12"
                md="12"
                lg="12"
              >
                <v-radio-group
                  v-model="multipleDates"
                  row
                  @mouseup="clearDates"
                >
                  <v-radio
                    label="Seleccionar una fecha"
                    color="indigo"
                    value="A"
                  ></v-radio>
                  <v-radio
                    label="Seleciconar multiples fechas"
                    color="indigo"
                    value="B"
                  ></v-radio>
                  <v-radio
                    label="Seleccionar por dias recurrentes"
                    color="indigo"
                    value="C"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 d-flex justify-center flex-row py-4">
              <v-col class="pa-0 px-0" cols="12" md="6" lg="6">
                <div
                  class="text-center overline mb-3 indigo lighten-4 black--text font-weight-bold"
                >
                  Seleccionar nuevas fechas del evento
                </div>
                <div class="px-2">
                  <v-date-picker
                    v-if="multipleDates === 'B'"
                    v-model="eventDates"
                    multiple
                    scrollable
                    no-title
                    full-width
                    :events="(val) => eventDatesCopy.includes(val)"
                    event-color="amber darken-4"
                    color="indigo darken-3"
                    :min="minDate"
                  ></v-date-picker>
                  <v-date-picker
                    v-else-if="multipleDates === 'A'"
                    v-model="eventDate"
                    scrollable
                    no-title
                    :events="(val) => eventDatesCopy.includes(val)"
                    event-color="indigo darken-3"
                    full-width
                    color="indigo darken-3"
                    :min="minDate"
                  ></v-date-picker>
                  <div v-else>
                    <v-row dense>
                      <v-col v-for="(day, index) in weekDayNames" :key="index">
                        <v-checkbox
                          class="mr-3"
                          :key="day.name"
                          v-model="day.isSelected"
                          :label="day.name"
                          dense
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row align="center" class="pt-3" dense>
                      <v-checkbox
                        dense
                        v-model="currendateActive"
                        hide-details
                        label="Fecha actual"
                        class="shrink mr-2 mt-0"
                        @mouseup="
                          currenDate = new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                      ></v-checkbox>
                    </v-row>
                    <v-row dense class="pt-5">
                      <v-menu
                        v-if="!currendateActive"
                        v-model="menuCurrenDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            class="mr-3"
                            label="Fecha inicio"
                            dense
                            v-model="currenDate"
                            persistent-hint
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="currenDate"
                          @input="menuCurrenDate = false"
                          :min="minDate"
                          :max="
                            new Date(new Date().getFullYear(), 11, 31)
                              .toISOString()
                              .substr(0, 10)
                          "
                        >
                        </v-date-picker>
                      </v-menu>
                      <v-menu
                        v-model="menuFinalDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            dense
                            label="Fecha final"
                            v-model="finalDate"
                            persistent-hint
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="finalDate"
                          @input="menuFinalDate = false"
                          :min="minDate"
                          :max="
                            new Date(new Date().getFullYear(), 11, 31)
                              .toISOString()
                              .substr(0, 10)
                          "
                        >
                        </v-date-picker>
                      </v-menu>
                    </v-row>
                    <v-row> </v-row>
                  </div>
                </div>
              </v-col>

              <v-divider vertical inset></v-divider>

              <v-col class="pa-0" cols="12" md="6" lg="6">
                <div
                  class="text-center overline indigo lighten-4 black--text font-weight-bold"
                >
                  Seleccionar Horarios de las nuevas fechas
                </div>
                <v-col
                  cols="12"
                  md="12"
                  lg="12"
                  class="pa-0 d-flex justify-center"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    class="pa-0 px-2 my-2"
                    elevation="0"
                    color="indigo darken-4"
                    text
                    @click="handleTime()"
                  >
                    Nueva Hora
                    <v-icon right> mdi-clock-time-four-outline </v-icon>
                  </v-btn>
                  <datetime
                  
                    input-class="d-none"
                    @input="addSchedule(eventTime)"
                    ref="activeTime"
                    type="time"
                    v-model="eventTime"
                    title="Seleccionar hora"
                    :phrases="{ ok: 'Aceptar', cancel: 'Cancelar' }"
                    value-zone="America/Mexico_City" 
                  ></datetime>
                </v-col>
                <v-col class="pa-0 px-2" cols="12">
                  <v-simple-table id="scheduleTimes">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">Hora</th>
                          <th class="text-right">Eliminar horario</th>
                        </tr>
                      </thead>
                      <tbody style="boder: 1px solid red !important">
                        <tr v-for="(item, index) in schedules" :key="index">
                          <td class="text-center">{{ item }}</td>
                          <td class="text-right">
                            <v-btn
                              color="indigo"
                              icon
                              @click="discardSchedule(index)"
                            >
                              <v-icon size="25px"> mdi-delete </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-col>
            </v-row>

            <v-row class="pa-0 pt-4 pb-8">
              <v-col class="pa-0" cols="12" md="6" lg="6">
                <div
                  class="mb-8 overline text-center mb-5 indigo lighten-4 black--text font-weight-bold"
                >
                  Preventa para la(s) fecha(s) de evento
                </div>
                <div class="px-4">
                  <v-menu
                    v-if="multipleDates === 'A'"
                    v-model="presaleDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="presale"
                        class="mt-n5"
                        persistent-hint
                        hint="Fecha"
                        label="Fecha de preventa del evento"
                        prepend-icon="mdi-calendar"
                        readonly
                        :disabled="!eventDate"
                        :rules="[rules.required]"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="presale"
                      :max="eventDate"
                      @input="presaleDateMenu = false"
                      :min="minDate"
                    ></v-date-picker>
                  </v-menu>
                  <v-select
                    v-else
                    v-model="presaleMonths"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    :items="numberMonthsPresale"
                    label="Cantidad de tiempo para preventa"
                    hint="A cada fecha de evento se le ajustará el tiempo para su preventa"
                    item-value="value"
                    item-text="text"
                    dense
                  ></v-select>
                </div>
              </v-col>

              <v-divider vertical></v-divider>

              <v-col class="text-right pa-0" cols="12" md="6" lg="6">
                <div
                  class="mb-4 overline text-center indigo lighten-4 black--text font-weight-bold"
                >
                  Asignar límite de personas por horario
                </div>
                <div class="px-4">
                  <v-text-field
                    :disabled="typeof max === 'number'"
                    v-model="addMax"
                    prepend-icon="mdi-account-group"
                    :rules="[rules.zero]"
                    type="text"
                    @keypress="checkit"
                    @blur="verifyEmptyFile(addMax)"
                    label="Límite de personas"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
            <v-row class="pa-0 pt-4 pb-8">
              <v-col class="text-right" cols="12">
                <div
                  class="overline text-center indigo lighten-4 black--text font-weight-bold"
                >
                  DESCRIPCIÓN PARA LA(S) FECHA(S) DEL EVENTO
                </div>
                <v-textarea
                  class="pt-3"
                  v-model="description"
                  :hint="
                    multipleDates
                      ? 'A cada fecha de evento se le asignará la descripción'
                      : ''
                  "
                  outlined
                  label="Descripción"
                  no-resize
                  height="150"
                  counter="150"
                  :rules="[
                    (v) =>
                      v.length === 0 ||
                      v.length <= 150 ||
                      'No puedes exceder los 150 caracteres para la descripción',
                  ]"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="indigo darken-4" @click="$emit('cancelProcess')">
          Cancelar
        </v-btn>
        <v-btn
          text
          outlined
          color="indigo darken-4"
          @click="confirmSavingDates"
        >
          Guardar Fecha(s)
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "addNewDates",
  props: ["id_event", "event_config", "max", "showDatesDialog"],
  data() {
    return {
      multipleDates: "A",
      disableSwitch: false,

      datesPreview: [],
      headerDates: [
        {
          text: "Fecha del evento",
          class: "indigo lighten-4",
          align: "center",
          value: "date_event",
        },
        {
          text: "Fecha de Preventa",
          class: "indigo lighten-4",
          value: "presale",
          align: "center",
        },
        {
          text: "Hora del Evento",
          class: "indigo lighten-4",
          value: "time_event",
          align: "center",
        },
        {
          text: "Limite de personas",
          class: "indigo lighten-4",
          sortable: false,
          value: "max",
          align: "center",
        },
        {
          text: "Eliminar fecha",
          class: "indigo lighten-4",
          value: "deleteDate",
          sortable: false,
          align: "center",
        },
      ],
      headerSchedules: [
        {
          text: "Hora",
          class: "indigo lighten-4",
          align: "center",
          value: "time",
        },
        {
          text: "Eliminar hora",
          class: "indigo lighten-4",
          value: "deleteDate",
          sortable: false,
          align: "right",
        },
      ],
      eventDates: [],
      eventDatesCopy: [],
      eventDate: null,
      presale: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      eventTime: null,
      addMax: 0,
      presaleMonths: 1,
      numberMonthsPresale: [
        {
          text: "Un mes",
          value: 1,
        },
        {
          text: "Dos meses",
          value: 2,
        },
        {
          text: "Tres mes",
          value: 3,
        },
      ],
      schedules: [],
      eventDateMenu: false,
      presaleDateMenu: false,
      timeMenu: false,
      minDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      rules: {
        required: (v) => !!v || "Este campo es requerido",
        zero: (v) => v >= 0 || "El número de personas no es válido!",
        minSchedules: (v) =>
          v.length > 0 || "Debe configurar al menos un horario!",
      },

      // calendar data
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      description: "",
      weekDayNames: [
        { value: 1, name: "Lunes", isSelected: false },
        { values: 2, name: "Martes", isSelected: false },
        { values: 3, name: "Miércoles", isSelected: false },
        { values: 4, name: "Jueves", isSelected: false },
        { values: 5, name: "Viernes", isSelected: false },
        { values: 6, name: "Sábado", isSelected: false },
        { values: 0, name: "Domingo", isSelected: false },
      ],
      currenDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      finalDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menuCurrenDate: false,
      menuFinalDate: false,
      currendateActive: true,
    };
  },
  mounted() {
    this.addMax = this.max ?? 0;
  },
  methods: {
    handleTime() {
      this.$nextTick(() => {
        this.$refs.activeTime.$el.querySelector("input").click();
      });
    },
    checkConfigSchedule() {
      return this.schedules.length > 0;
    },
    discardSchedule(index) {
      this.schedules.splice(index, 1);
    },
    discardDate(discardDateX) {
      const index = this.datesPreview.indexOf(discardDateX);

      if (index !== -1) {
        this.datesPreview.splice(index, 1);
      }

      const found = this.datesPreview.filter(
        ({ date_event }) => discardDateX.date_event == date_event
      );
      if (found.length === 0) {
        const index2 = this.eventDatesCopy.indexOf(discardDateX.date_event);
        if (index2 !== -1) {
          this.eventDatesCopy.splice(index2, 1);
        }
      }
      this.$refs.CalendarTool.closeMiniEventInfo();
    },

    addSchedule(schedule) {
      if (schedule) {
        const valor = Date.parse(schedule)
        const horas = new Date(valor).getHours();
        const minutos = new Date(valor).getMinutes();
        schedule = horas + ":" + minutos
        this.schedules = [schedule, ...this.schedules];
        this.schedules.sort();
        this.eventTime = null;
        this.timeMenu = false;
      }
    },
    preparationOfDates() {
      let self = this;
      if (this.multipleDates === "A") {
        this.schedules.forEach((timeEvent) => {
          this.datesPreview = [
            {
              name: "Nuevo Evento",
              start: new Date(`${this.eventDate}T${timeEvent}`).getTime(),
              end: null,
              color: this.colors[this.rnd(0, this.colors.length - 1)],
              timed: true,

              date_event: this.eventDate,
              presale: this.presale,
              time_event: timeEvent,
              max: self.max ? Number(self.max) : Number(this.addMax),
              description: this.description,
            },
            ...this.datesPreview,
          ];
        });
        if (!this.eventDatesCopy.includes(this.eventDate))
          this.eventDatesCopy = [...this.eventDatesCopy, this.eventDate];
        this.eventDate = null;
      } else {
        if (this.multipleDates === "C") {
          this.eventDates = [];
          this.eventDates = this.getDaysBetweenDates(
            new Date(this.currenDate),
            new Date(this.finalDate),
            this.weekDayNames
          );
        }
        const fechaActual = new Date();
        this.eventDates.forEach((date) => {
          let fecha = new Date(date);
          fecha.setMonth(fecha.getMonth() - this.presaleMonths);
          if (fecha < fechaActual) {
            fecha = fechaActual;
          }
          this.schedules.forEach((timeEvent) => {
            self.datesPreview = [
              ...self.datesPreview,
              {
                name: "Nuevo Evento",
                start: new Date(`${date}T${timeEvent}`).getTime(),
                end: null,
                color: self.colors[self.rnd(0, self.colors.length - 1)],
                timed: true,

                date_event: date,
                presale: new Date(
                  fecha - new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substr(0, 10),
                time_event: timeEvent,
                max: self.max ? Number(self.max) : Number(self.addMax),
                description: this.description,
              },
            ];
          });
        });
        this.eventDates.forEach((dateX) => {
          if (!this.eventDatesCopy.includes(dateX))
            this.eventDatesCopy = [...this.eventDatesCopy, dateX];
        });
        // this.eventDates = [];
      }
      this.schedules = [];
      this.eventTime = null;
      this.addMax = this.max ?? 0;
      this.$refs.dateForm.resetValidation();

      this.saveDates();
    },
    getDaysBetweenDates(startDate, endDate, weekDayNames) {
      const eventDates = [];
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        if (currentDate.getDay() === 0 && weekDayNames[0].isSelected === true) {
          eventDates.push(new Date(currentDate));
        } else if (
          currentDate.getDay() === 1 &&
          weekDayNames[1].isSelected === true
        ) {
          eventDates.push(new Date(currentDate));
        } else if (
          currentDate.getDay() === 2 &&
          weekDayNames[2].isSelected === true
        ) {
          eventDates.push(new Date(currentDate));
        } else if (
          currentDate.getDay() === 3 &&
          weekDayNames[3].isSelected === true
        ) {
          eventDates.push(new Date(currentDate));
        } else if (
          currentDate.getDay() === 4 &&
          weekDayNames[4].isSelected === true
        ) {
          eventDates.push(new Date(currentDate));
        } else if (
          currentDate.getDay() === 5 &&
          weekDayNames[5].isSelected === true
        ) {
          eventDates.push(new Date(currentDate));
        } else if (
          currentDate.getDay() === 6 &&
          weekDayNames[6].isSelected === true
        ) {
          eventDates.push(new Date(currentDate));
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return eventDates;
    },
    clearDates() {
      this.datesPreview = [];
      this.eventDates = [];
      this.eventDate = null;
      this.eventDatesCopy = [];
      this.schedules = [];
      this.addMax = 0;
      this.eventTime = null;
      this.currendateActive = true;
      this.currenDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.finalDate = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);

      this.weekDayNames.forEach((day) => {
        day.isSelected = false;
      });
      this.$refs.dateForm.resetValidation();
    },
    checkit(event) {
      const keyCode = event.keyCode || event.which;
      const keyValue = String.fromCharCode(keyCode);

      if (/\D/.test(keyValue)) {
        event.preventDefault();
      }
    },
    verifyEmptyFile(item) {
      if (item.length === 0) {
        item = 0;
        this.addMax = 0;
      } else {
        this.addMax = parseInt(item);
      }
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    confirmSavingDates() {
      if (this.schedules !== 0 && this.$refs.dateForm.validate()) {
        this.$root
          .$swal({
            title: "¿Está seguro(a) de guardar la(s) fecha(s)?",
            text: "Una vez generadas las fechas NO SE PODRÁN MODIFICAR si ya comenzó la preventa",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, estoy seguro(a)",
          })
          .then((response) => {
            if (response.isConfirmed) {
              // this.saveDates()
              this.preparationOfDates();
            }
          });
      } else {
        this.$root.swalAlert(
          "error",
          "Tiene que configurar al menos una fecha con sus correspondientes horarios!"
        );
      }
    },
    saveDates() {
      this.$root
        .post("/Admin/createEventDates", {
          id_event: this.id_event,
          event_config: this.event_config,
          newDates: this.datesPreview,
        })
        .then((response) => {
          let info = response.data;
          if (info.success === "TRUE") {
            this.$emit("addNewDates", info.dates);
            this.$root.swalAlert("success", "Fecha(s) Generada(s)");
            this.clearDates();
          } else {
            this.$root.swalAlert("warning", "Error", info.Msg);
          }
        })
        .catch((e) => {
          this.$root.swalAlert("error", "Error", e.message);
        });
    },
    getLastDayOfYear() {
      return;
    },
  },
};
</script>

<style scoped>
#scheduleTimes {
  max-height: 220px !important;
  overflow-y: auto;
}
</style>
