<template>
  <v-container fluid class="pa-4">
    <v-card class="pa-3" elevation="3">
      <v-toolbar flat>
        <v-toolbar-title>
          <v-icon size="25" class="pr-3 pb-1">mdi-pencil</v-icon>
          <span class="headline font-weight-bold">Editar Evento</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tooltip
            bottom
            color="grey darken-2"
            content-class="grey--text text--lighten-5 rounded-b-xl"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="mx-6 d-flex justify-space-around align-center"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="30">mdi-map-marker</v-icon>
                <span>&nbsp;{{ MyEvent.id_place }}</span>
              </div>
            </template>
            <span>ID del Lugar</span>
          </v-tooltip>
          <v-tooltip
            bottom
            color="grey darken-2"
            content-class="grey--text text--lighten-5 rounded-b-xl"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="mx-6 d-flex justify-space-around align-center"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="30">mdi-stadium</v-icon>
                <span>&nbsp;{{ MyEvent.id_event }}</span>
              </div>
            </template>
            <span>ID del Evento</span>
          </v-tooltip>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs
        v-model="tab"
        class="pb-3"
        background-color="transparent"
        color="basil"
        grow
      >
        <v-tabs-slider color="red"></v-tabs-slider>
        <v-tab>
          <v-icon left>mdi-text-box-outline</v-icon>
          Información General
        </v-tab>
        <v-tab>
          <v-icon left>{{selectConfigByEventType.icon}}</v-icon>
          {{ selectConfigByEventType.name }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <EventDetails :MyEvent="MyEvent" :currenIdPlace="currenIdPlace" />
        </v-tab-item>
        <v-tab-item>
          <EventType1
            class="mt-4"
            ref="EventType1X"
            v-if="MyEvent.event_config == 1"
            :MyEvent="MyEvent"
            :vtab="tab"
            @updateEvents="updateEvent"
          />
          <EventType3
            class="mt-4"
            ref="EventType3X"
            v-if="MyEvent.event_config == 3"
            :MyEvent="MyEvent"
            :vtab="tab"
          />
          <EventType4
            class="mt-4"
            ref="EventType4X"
            v-if="MyEvent.event_config == 4"
            :MyEvent="MyEvent"
            :vtab="tab"
          />
          <EventType5
            class="mt-4"
            ref="EventType4X"
            v-if="MyEvent.event_config == 5"
            :MyEvent="MyEvent"
            :vtab="tab"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import EventDetails from "./EventDetails.vue";
import EventType1 from "./EventType1.vue";
import EventType3 from "./EventType3.vue";
import EventType4 from "./EventType4.vue";
import EventType5 from "./EventType5.vue";
export default {
  name: "EditEvent",
  components: {
    EventType1,
    EventType3,
    EventType4,
    EventType5,
    EventDetails,
  },
  data() {
    return {
      MyEvent: null,
      tab: null,
      configByEventType: [
        { id: 1, name: "Configuración de la URL", icon: "mdi-web" },
        { id: 3, name: "Configruración de las Subcategorías", icon: "mdi-label-multiple-outline" },
        { id: 4, name: "Configuración de las fechas", icon: "mdi-calendar-blank-multiple" },
        { id: 5, name: "Configuración de las fechas y asientos", icon: "mdi-calendar-account-outline" },
      ],
      selectConfigByEventType: null,
      
      // MyEvent: "",
      // currenIdPlace: {
      //   id_place: null,
      //   eventplace: null,
      //   city: null,
      //   state: null,
      // },
      // typeSitesx: [],
      // vtab: 0,
      // lengX: "ES",
      // imgTemp: null,
      // files: null,
      // especialConfigByEventType: {
      //   1: "Configuración de la URL del Evento",
      //   3: "Configruración de las Subcategorías",
      //   4: "Configuración de las fechas del evento",
      //   5: "Configuración de las fechas del evento y asientos del Evento",
      // },
      // tabs: null,
    };
  },
  watch: {
    currenIdPlace: function (val) {
      this.$store.dispatch("events/setEventSelected_A", {
        id_event: null,
        event_config: null,
        id_place: val.id_place,
      });
    },
  },
  created() {
    this.MyEvent = this.$route.params.MyEvent;
    this.currenIdPlace = Object.assign({}, this.$route.params.currenIdPlace);
    this.typeSitesx = [...this.$store.state.catalogs.typeSites];
    this.selectConfigByEventType = this.getEspecialConfigByEventType(this.MyEvent.event_config);
  },
  mounted(){
    
  },
  methods: {
    back() {
      this.$router.push({ name: "Events" });
    },
    updateEvent(){
      this.$root.post("/Admin/GetEvents", { 
        id_place: this.$store.state.events.eventSelected.id_place 
      })
      .then((response) => {
        let info = response.data;
       
        if(info.success == 'TRUE') {
          let eventsCharged = info.Data;
          this.events = eventsCharged;
        } else {
          this.events = []
          this.$root.Toast("info", info.Msg);
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error al cargar los eventos", e.message);
      })
      .finally(() => {
       
      });
    },
    GetEventsConfig(event_config) {
      let configurations = { ...this.$store.state.catalogs.event_config_desc };
      return configurations[event_config] || "Sin configurar";
    },
    updateGeneralInfo() {
      this.$root
        .post("/Admin/updateGeneralEventInfo", {
          id_event: this.MyEvent.id_event,
          name: this.MyEvent.name,
          category: this.MyEvent.category,
          img: this.MyEvent.img,
          updateImage: this.imgTemp,
        })
        .then((response) => {
          let info = response.data;
          if (info.success === "TRUE") {
            this.MyEvent.img = info.Data.img;
            let index = this.events.findIndex(
              ({ id_event }) => id_event === this.MyEvent.id_event
            );
            //   if(index !== -1) {
            //     this.events[index].img = this.MyEvent.img;
            //     this.events[index].category = this.MyEvent.category;
            //   }
            //   this.compareGeneralInfo = JSON.stringify({...this.MyEvent})

            this.$root.swalAlert("success", "Información general actualizada");
          } else {
            this.$root.swalAlert(
              "error",
              "Error en la actualización",
              info.Msg
            );
          }
        })
        .catch((e) => {
          this.$root.swalAlert("error", "Error en la actualización", e.message);
        })
        .finally(() => {
          this.imgTemp = null;
        });
    },
    readURL() {
      let self = this;

      if (this.files) {
        if (
          this.files.type !== "image/png" &&
          this.files.type !== "image/jpeg" &&
          this.files.type !== "image/jpg" &&
          this.files.type !== "image/webp"
        ) {
          this.imgTemp = null;
          return this.$root.swalAlert(
            "error",
            "Archivo no permitido",
            "Sólo se admiten archivos JPG, JPEG, PNG o WEBP"
          );
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          self.imgTemp = e.target.result;
        };
        reader.readAsDataURL(this.files);
      }
    },
    async UploadImg() {
      this.$refs.fileup.$el.querySelector("input").click();
    },
    updateAcercade() {
      this.$root
        .post("/Admin/updateGeneralEventInfo", {
          id_event: this.MyEvent.id_event,
          acercade: this.MyEvent.acercade,
          category: this.MyEvent.category,
          img: this.MyEvent.img,
          updateImage: null,
        })
        .then((response) => {
          let info = response.data;
          if (info.success === "TRUE") {
            //   let index = this.events.findIndex( ({ id_event }) => id_event === this.MyEvent.id_event );
            //   if(index !== -1) {
            //     this.events[index].acercade = this.MyEvent.acercade;
            //   }
            //   this.compareGeneralInfo = JSON.stringify({...this.MyEvent})

            this.$root.swalAlert("success", "Descripción general actualizada");
          } else {
            this.$root.swalAlert(
              "error",
              "Error en la actualización",
              info.Msg
            );
          }
        })
        .catch((e) => {
          this.$root.swalAlert("error", "Error en la actualización", e.message);
        });
    },
    getEspecialConfigByEventType(id){
      return this.configByEventType.find(objeto => objeto.id === id);
    }

  },
};
</script>
<style scoped>
.box {
  border-radius: 5px;
  width: 340px;
  height: 200px;
  background: #fff;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  border: 2px solid #3f414a;
  background-size: cover;
  background-position: 100% 100%;
}
.without {
  border: 0px solid #3f414a !important;
  box-shadow: 0 0 0px rgb(0 0 0 / 10%) !important;
}
</style>
