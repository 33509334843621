<template>
  <v-dialog
    v-model="addTicketDialog"
    persistent
    scrollable
    max-width="1100"
    v-if="vEntorno">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small color="grey darken-3" dark v-bind="attrs" v-on="on" @click="loadAvailableTickets"> Vincular </v-btn>
    </template>
    <v-card>
      <v-toolbar class="grey darken-4">
        <v-toolbar-title class="white--text">Tickets disponibles</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon dark @click="addTicketDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <div>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" md="6" style="padding: 20px 20px 20px 20px">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-data-table
          class="mt-2"
          fixed-header
          :headers="ticketsHeader"
          :items="listTicketsAvailable"
          :items-per-page="5"
          :search="search"
          style="padding-top: 10px"
          :loading="loadingtickets"
          no-data-text="No hay boletos disponibles. Contacte a soporte para que se habiliten boletos">
          <template v-slot:[`item.price`]="{ item, index }">
            <div> ${{ item.price }} </div>
          </template>
          
          <template v-slot:[`item.active`]="{ item, index }">
            <v-row justify="center">
              <v-switch
                v-model="item.active"
                inset
                color="success"
                @change="changeSwitch(item, index)"
              ></v-switch>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="pa-0 px-7 pb-5">
        <v-spacer></v-spacer>
        <v-btn 
            dark 
            :disabled="listTicketsAvailable.length === 0 || newTicketsAdded.length === 0" 
            color="orange darken-3" 
            @click="addTicketsSelect()">
          Vincular
        </v-btn>
        <v-btn color="white" @click="addTicketDialog = false">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ListTickets",
  props: ["index"],
  data() {
    return {
      vEntorno: null,
      ticketsHeader: [
        {
          text: "Ticket",
          align: "start",
          value: "id_ticket",
          class: "grey lighten-3"
        },
        {
          text: "Nombre del boleto",
          align: "start",
          value: "alias",
          class: "grey lighten-3"
        },
        { text: "Nombre en Webshop (Español)", value: "alias2[ES]", align: "left",class: "grey lighten-3" },
        { text: "Ticket Type", value: "id_tickettype", align: "center", class: "grey lighten-3" },
        { text: "Person Type", value: "id_persontype", align: "center", class: "grey lighten-3" },
        { text: "Pool", value: "id_npool", align: "center", class: "grey lighten-3" },
        { text: "Precio del Boleto", value: "price", align: "center", class: "grey lighten-3" },
        {
          text: "Vincular",
          value: "active",
          sortable: false,
          align: "center",
          class: "grey lighten-3"
        },
      ],
      listTicketsAvailable: [ ],
      search: "",
      addTicketDialog: false,
      loadingtickets: false,
      newTicketsAdded: []
    };
  },
  mounted() {
    this.vEntorno = this.$vEntorno;
  },
  methods: {
    loadAvailableTickets() {
      this.loadingtickets = true;
      this.$root.post("/Admin/availableTickets", { 
        id_place: this.$store.state.events.eventSelected.id_place 
      })
      .then((response) => {
        let info = response.data;
        if(info.success == 'TRUE') {
          this.listTicketsAvailable = info.Data
        } else {
          this.listTicketsAvailable = []
          this.$root.swalAlert("warning", "", info.Msg);
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error en el proceso de carga", e.message);
      })
      .finally(() => {
        this.loadingtickets = false;
      });
    },
    changeSwitch(item, index) {
      const { id_event } = this.$store.state.events.eventSelected
      const id_cataloge = this.$store.state.events.currentCatalogeSelected
      
      const relationIndex = this.newTicketsAdded.indexOf(({ id_ticket }) => id_ticket === item.id_ticket);
      if(relationIndex !== -1) return
      
      if(item.active) {
        const newTicket = { id_event, id_cataloge, id_ticket: item.id_ticket }
  
        this.newTicketsAdded = [        
          ...this.newTicketsAdded,
          newTicket
        ] 
      } else {
        this.newTicketsAdded.splice(relationIndex, 1)
      }
    },
    addTicketsSelect() {
      this.$swal({
        title: "Vincular nuevos boletos",
        text: "¿Está seguro(a) de vincular los boletos seleccionados?",
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#212121",
        confirmButtonText: "Sí, seguro(a)",
        confirmButtonColor: "#FF6F00",
        
      }).then((confirmed) => {
        if (confirmed.isConfirmed) {
          this.$emit("addTicketsSelect", 1, null, this.newTicketsAdded)
        }
        this.newTicketsAdded = []
        this.resetTickets()
        this.addTicketDialog = false
      });
    },
    resetTickets() {
      this.listTicketsAvailable.forEach( ticket => {
        ticket.active = false
      });
    }
  },
};
</script>

<style scoped>
.new-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

</style>
