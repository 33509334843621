<template>
  <v-dialog v-model="subcateDialog" persistent scrollable no-click-animation max-width="1200">
    <v-card style="min-height: 600px; overflow: auto; overflow-x: hidden">
      <v-toolbar dark class="grey darken-4">
        Grupo de boletos para : <b>{{ subcategorie }}</b>
        <v-spacer></v-spacer>
        <v-toolbar-items class="d-flex align-center justify-center">
          <v-btn icon dark @click="ClosedialogConfig">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-card class="ma-4" outlined>
          <v-card-text v-if="vEntorno">
            <v-container v-if="vEntorno.Subevents[index]">
              <v-row align="center">
                <v-col class="text-left">
                  <div class="MyTitle">Datos Generales del Grupo</div>
                </v-col>
                <v-col class="text-right">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :disabled="generalInfoCopy === JSON.stringify(generalInfo)"
                        @click="updateGeneralInfoSubcate" small color="amber accent-4" v-bind="attrs" v-on="on">
                        Guardar
                      </v-btn>
                    </template>
                    <span>Guardar cambios de la información general de la subcategoría</span>
                  </v-tooltip>
                </v-col>
                <v-col>
                  <v-tabs value="id" v-model="lenguageTab" background-color="transparent" color="basil"
                    @change="lenguageChange" grow>
                    <v-tab v-for="item in language" :key="item.id">
                      {{ item.name }}
                    </v-tab>
                  </v-tabs>
                </v-col>
              </v-row>
              <v-form ref="generalInfoForm">
                <v-row class="mt-3">
                  <v-text-field class="pa-2" :label="`Nombre de la subcategoria ${lenguageTab === 0 ? 'ESPAÑOL' : 'INGLÉS'
                    }`" :placeholder="`Escribe un nombre de la subcategoria en ${lenguageTab === 0 ? 'ESPAÑOL' : 'INGLÉS'
                      }`" v-model="generalInfo.title[leng]" :hint="`${lenguageTab === 0
                        ? 'Ejemplo: Entrada General al Estadio'
                        : 'Example: General Entrance to the Stadium'
                        }`" persistent-hint outlined></v-text-field>
                </v-row>
                <v-row class="mt-2">
                  <v-textarea class="pa-2" v-model="generalInfo.description[leng]" outlined :counter="250"
                    :rules="[validDescription]" :label="`Descripción de la subcategoria ${lenguageTab === 0 ? 'ESPAÑOL' : 'INGLÉS'
                      }`" height="100" no-resize outline />
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>

        <v-card class="ma-4" outlined>
          <v-card-text v-if="vEntorno">
            <v-container v-if="vEntorno.Subevents[index]">
              <v-col cols="12" style="padding-top: 0px">
                <div>
                  <ListEspecif v-if="ListEspecifShow" :index="index" :leng="leng" @Especlick="Especlick" />
                </div>
              </v-col>
            </v-container>
          </v-card-text>
        </v-card>

        <v-card class="ma-4" outlined>
          <v-card-text v-if="vEntorno">
            <v-container v-if="vEntorno.Subevents[index]">
              <v-col cols="12" style="padding-top: 0; padding-bottom: 22px">
                <div style="width: 100%">
                  <shortGallery :index="index" />
                </div>
              </v-col>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card class="ma-4" outlined>
          <v-card-text v-if="vEntorno">
            <v-container v-if="vEntorno.Subevents[index]">
              <v-row>
                <v-col cols="6">
                  <span class="MyTitle">Boletos</span>
                </v-col>
                <v-col cols="6" class="text-right">
                  <ListTickets :index="index" @addTicketsSelect="configRelationTickets" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table @click:row="openBoletos" :headers="ticketsHeader"
                    :items="vEntorno.Subevents[index].tickets" :search="search" style="padding-top: 10px"
                    no-data-text="¡Aún no hay boletos vinculados para esta subcategoría!">

                    <template v-slot:[`item.price`]="{ item, index }">
                      <div> ${{ item.price }}</div>
                    </template>
                    <template v-slot:[`item.delete`]="{ item, index }">
                      <v-btn icon @click="deleteTicketSelect(item, index)">
                        <v-icon> mdi-link-off </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                  <v-dialog v-if="dialogBoletos" v-model="dialogBoletos" width="700">
                    <v-card>
                      <v-card-title>Editar boleto</v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field label="Nombre en ESPAÑOL" v-model="boleto.nameES"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-text-field label="Nombre en INGLES" v-model="boleto.nameEN"  ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row v-if="$store.state.auth.user.admin === 1">
                            <v-col cols="4">
                              <v-text-field label="Precio" prefix="$" v-model="boleto.price"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn class="error" @click="closeBoletos">Cancelar</v-btn>
                        <v-btn class="success"
                          @click="updateBoleto(boleto.id_ticket, boleto.nameES, boleto.nameEN)">Guardar</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ListEspecif from "../Tools/ListEspecifi.vue";
import shortGallery from "../Tools/ShortGallery.vue";
import ListTickets from "../Tools/ListTickets.vue";

export default {
  name: "subcatogorieDialog",
  props: ["subcateDialog", "subcategorie", "index"],
  components: {
    ListEspecif,
    shortGallery,
    ListTickets,
  },
  data() {
    return {
      vEntorno: null,
      generalInfo: {
        description: {},
        title: {},
      },
      generalInfoCopy: null,

      ListEspecifShow: true,
      search: null,
      item_especifica: { info: "", icon: null, index: null },

      defCountry: { icon: "" },
      leng: "ES",
      language: [
        { id: "ES", name: "Español" },
        { id: "EN", name: "Inglés" },
      ],
      lenguageTab: 0,
      dialogEspeciX: false,
      dialogBoletos: false,
      boleto: {},

      ticketsHeader: [
        {
          text: "Id",
          align: "center",
          value: "id_ticket",
          class: "grey lighten-3"
        },
        {
          text: "Nombre del boleto",
          align: "center",
          value: "alias",
          class: "grey lighten-3"
        },
        {
          text: "Nombre en Webshop (Español)",
          align: "center",
          value: "alias2[ES]",
          class: "grey lighten-3"
        },
        { text: "Ticket Type", value: "id_tickettype", align: "center", class: "grey lighten-3" },
        { text: "Person Type", value: "id_persontype", align: "center", class: "grey lighten-3" },
        { text: "Pool", value: "id_npool", align: "center", class: "grey lighten-3" },
        { text: "Precio del Boleto", value: "price", align: "center", class: "grey lighten-3" },
        {
          text: "Desvincular boleto",
          value: "delete",
          sortable: false,
          align: "center",
          class: "grey lighten-3"
        },
      ],
    };
  },
  created() {
    this.vEntorno = this.$vEntorno;
  },
  mounted() {
    this.chargeGeneralInfoData();
  },
  methods: {
    validDescription(value) {
      // if (
      //   !!this.generalInfo.description["ES"] !==
      //   !!this.generalInfo.description["EN"]
      // ) {
      //   return "Si se va a añadir descripción, se deben llenar en AMBOS IDIOMAS";
      // }
      if (value && value.length >= 250) {
        return "La descripción debe tener menos de 250 caracteres";
      } else {
        return true;
      }
    },
    chargeGeneralInfoData() {
      this.vEntorno = this.$vEntorno;

      this.generalInfo.description = {
        ...this.vEntorno.Subevents[this.index].description,
      };
      this.generalInfo.title = { ...this.vEntorno.Subevents[this.index].title };

      this.generalInfoCopy = JSON.stringify(this.generalInfo);
    },
    updateGeneralInfoSubcate() {
      if (this.$refs.generalInfoForm.validate()) {
        this.$root
          .post("/Admin/updateSubcategorie", {
            id_event: this.$store.state.events.eventSelected.id_event,
            id_cataloge: this.$store.state.events.currentCatalogeSelected,
            typeUpdate: 1,
            generalInfo: this.generalInfo,
          })
          .then((response) => {
            let info = response.data;

            if (info.success == "TRUE") {
              this.vEntorno.Subevents[this.index].title =
                this.generalInfo.title;
              this.vEntorno.Subevents[this.index].description =
                this.generalInfo.description;

              this.$set(this.$vEntorno, "Subevents", this.vEntorno.Subevents);

              this.chargeGeneralInfoData();

              this.$root.swalAlert("success", "Información actualizada");
            } else {
              this.$root.swalAlert("error", info.Msg);
            }
          })
          .catch((e) => {
            this.$root.swalAlert("error", e.message);
          });
      } else {
        this.$root.swalAlert("error", "Llene correctamente los campos");
      }
    },
    ClosedialogConfig() {
      this.$emit("closeSubcategorieConfig");
    },
    Especlick(item) {
      (this.item_especifica = {
        info: item.item.info,
        icon: item.item.icon,
        index: item.index,
      }),
        (this.defCountry = { icon: item.item.icon });
      this.dialogEspeciX = true;
    },
    lenguageChange(lenguage) {
      let lengX;
      if (lenguage === 0) lengX = "ES";
      if (lenguage === 1) lengX = "EN";

      this.leng = lengX || "ES";
    },
    deleteTicketSelect(item, index) {
      this.$swal({
        title: "Desvincular ticket seleccionado",
        text: "¿Esta seguro que desea desvincular el ticket seleccionado?",
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        cancelButtonColor: "#FF5252",
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#4CAF50",
        reverseButtons: true,
      }).then((confirmed) => {
        if (confirmed.isConfirmed) {
          this.configRelationTickets(2, item.id_relation, null)
          this.vEntorno.Subevents[this.index].tickets.splice(index, 1);
        }
      });
    },
    configRelationTickets(action, id_relation, tickets) {
      this.$root.post("/Admin/ticketRelationship", {
        action,
        id_relation,
        tickets
      })
        .then((response) => {
          let info = response.data;
          if (info.success == 'TRUE') {
            if (id_relation == null) {
              this.vEntorno.Subevents[this.index].tickets = [
                ...this.vEntorno.Subevents[this.index].tickets,
                ...info.Data
              ]
            }
            this.$root.swalAlert("success", "Boletos Actualizados");
          } else {
            this.$root.swalAlert("error", "Error al actualizar boletos", info.Msg);
          }
        })
        .catch(e => {
          this.$root.swalAlert("error", "Error", e.message);
        });
    },
    openBoletos(item, i) {
      this.boleto = {
        id_ticket: item.id_ticket,
        nameES: item.alias2.ES,
        nameEN: item.alias2.EN,
        price: item.price,
      };
      this.dialogBoletos = true;
    
    },
    closeBoletos() {
      this.dialogBoletos = false;
      this.boleto = {};

    },
    updateBoleto(id, nameES, nameEN) {
      const params = {
        tickets: {
            "ES": nameES,
            "EN": nameEN
          },
          id_ticket: id
      }
      this.$root
        .post("/Admin/changeNameTicket", params)
        .then((response) => {
          let info = response.data;

          if (info.success == "TRUE") {
            this.chargeGeneralInfoData();
            this.$root.swalAlert("success", "Información actualizada");
            this.dialogBoletos = false;
          } else {
            this.$root.swalAlert("error", info.Msg);
          }
        })
        .catch((e) => {
          this.$root.swalAlert("error", e.message);
        });
    }
  },
};
</script>

<style scoped>
.MyTitle {
  text-align: left;
  font-weight: 800;
  color: #6e6e6e;
  width: fit-content;
}
</style>
