<template>
  <div>
    <v-sheet height="64">              
      <v-toolbar flat >
        <v-btn
          outlined
          class="mr-4"
          color="grey darken-2"
          @click="setToday" >
          Today
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="prev" >
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          fab
          text
          small
          color="grey darken-2"
          @click="next" >
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
        <v-toolbar-title v-if="$refs.calendarEventDates">
          {{ $refs.calendarEventDates.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu
          bottom
          right >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              color="grey darken-2"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Día</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Semana</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Mes</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 días</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-sheet>
    <v-sheet  height="550" max-height="550">
      <v-calendar
        ref="calendarEventDates"
        v-model="focus"
        color="primary"
        :events="dates"
        :event-color="getEventColor"
        :type="type"
        @click:event="showEvent"
        @click:more="viewDay"
        @click:date="viewDay"
      ></v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-click = closeOnClick
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x >
        <v-card
          color="grey lighten-4"
          min-width="400px"
          flat >
          <v-toolbar
            :color="selectedEvent.color"
            dark >
            <v-toolbar-title>
              {{ selectedEvent.name }} {{ selectedEvent.cancel ? '&nbsp; CANCELADO' : new Date(currentDateX) >= new Date(selectedEvent.presale) ? "&nbsp; PREVENTA INICIADA":"" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn  :disabled="selectedEvent.cancel" icon v-bind="attrs"  v-on="{ ...tooltip, ...menu }">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
                </template>
                <span>Más acciones</span>
              </v-tooltip>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, index) in options" :key="index" link @click="clickAction(index, selectedEvent)">
                  <v-icon :color="selectedEvent.color" size="20" class="pr-1">{{ item.icon }}</v-icon>
                  <v-list-item-content style="font-size: 14px;">{{ item.text }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-card-text class="pa-0">
            <v-container fluid class="px-4">
              <v-row class="ma-0 pa-0 px-2 py-2">
                <v-col cols="12" class="pa-0">
                  <p v-if="!updateEvent"  @dblclick="updateEventDate(selectedEvent)"> {{ !selectedEvent.description ?'Sin descripción': selectedEvent.description.length > 50 ? selectedEvent.description.slice(0, 50) + "..." : selectedEvent.description}}</p>
                  <v-textarea v-if="updateEvent" ref="myTextArea" class="body-2 pa-0 ma-0" counter="100" :rules="[v => (v.length <= 100) || 'La descripción solo pueder tener un maximo de 100 caracteres']" dense v-model="selectedEvent.description" :color="selectedEvent.color" no-resize rows="1" row-height="25" placeholder="Escribe la descripción de la fecha" @blur="updateEvent=false"></v-textarea>
                </v-col>
              </v-row>
              <div class="d-flex flex-row align-auto justify-space-around">
                <div class="d-flex align-center flex-column justify-start">
                  <span class="caption text-uppercase">Fecha Evento</span>
                  <div class="date-style font-weight-bold text-capitalize">
                    {{ new Date(selectedEvent.start).toLocaleDateString('es-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"}) }}
                  </div>
                </div>
                <div class="d-flex align-stretch flex-column justify-start">
                  <span class="pb-2 caption text-uppercase">Hora Evento</span>
                  <v-chip :dark="!selectedEvent.cancel" :color="selectedEvent.cancel ? 'blue-grey lighten-3' : 'indigo darken-3'">
                    {{ selectedEvent.time_event}} Hrs
                  </v-chip>
                </div>
                <div class="d-flex align-center flex-column justify-start">
                  <span class="pb-2 caption text-uppercase">Max</span>
                  <v-chip :dark="!selectedEvent.cancel" :color="selectedEvent.cancel ? 'blue-grey lighten-3' : 'indigo darken-3'">
                    {{ selectedEvent.max}} perso.
                  </v-chip>
                </div>
              </div>
              <div class="mt-5 d-flex align-center flex-row justify-space-around">
                <span class="caption text-uppercase">Preventa</span>
                <span class="font-weight-bold text-capitalize">{{ fixDate(selectedEvent.presale) }}</span>
              </div>
              <div class="mt-4 py-3 text-center blue-grey lighten-5" v-if="selectedEvent.cancel">
                EVENTO CANCELADO
              </div>  
              <div class="mt-4 py-3 text-center indigo lighten-5" v-else-if="new Date(currentDateX) >= new Date(selectedEvent.presale)">
                PREVENTA INICIADA
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="indigo"
              @click="closeEventDate()">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              dark
              color="indigo"
              :disabled="detectChangeDescription()"
              :loading="loading"
              @click="saveUpdateEventDate(selectedEvent)" >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "CalendarTool", 
  props: ["dates", "readOnly"],
  data() {
    return {
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'Mes',
        week: 'Semana',
        day: 'Día',
        '4day': '4 Días',
      },
      selectedEvent: {},
      backSelectedEvent:null,
      updateEvent:false,
      selectedElement: null,
      selectedOpen: false,
      currentDateX: "",
      options:[
        {text:'Editar descripción de la fecha', icon:'mdi-pencil'},
        {text:'Cancelar fecha del eventos', icon:'mdi-cancel'},
      ],
      loading:false,
      changeDescription: false,
      closeOnClick: true,
    }
  },
  mounted() {
    this.getCurrentDate()
  },
  methods: {    
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor (event) {
      return event.color
    },
    setToday () {
      this.focus = ''      
      this.type = 'month'
    },
    prev () {
      this.$refs.calendarEventDates.prev()
    },
    next () {
      this.$refs.calendarEventDates.next()
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.backSelectedEvent = JSON.stringify({...this.selectedEvent})
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    clickAction(index,selectedEvent){
      switch(index){
        case 0:
          this.updateEventDate(selectedEvent)
          break
        case 1:
          this.discardDate(selectedEvent)
          break
        default:
          break
      }
    },
    updateEventDate(selectedEvent){
      if(!selectedEvent.cancel){
        this.updateEvent = true
      setTimeout(() => {
          this.$refs.myTextArea.$refs.input.focus();
        }, 50);
      } 
    },
    saveUpdateEventDate(selectedEvent){
      //Validaciones
      if(selectedEvent.description.length > 100){
        return this.$root.swalAlert("info", 'Información', 'La descripción solo pueder tener un maximo de 100 caracteres');
      }
      this.loading=true
      this.$root.post("/Admin/updateEventDate",{
        id_event: selectedEvent.id_event,
        id_eventd: selectedEvent.id_eventd,
        description: selectedEvent.description,
      })
      .then(response => {
        let inf = response.data;
        if(inf.success === "FALSE"){
          return this.$root.swalAlert("error", inf.message || inf.Msg);
        }
        this.selectedEvent.description = inf.Data[0].description
        let index = this.dates.findIndex( ({ id_eventd }) => id_eventd === this.selectedEvent.id_eventd);
        if(index !== -1) {
            this.dates[index].description = this.selectedEvent.description
          }
        this.backSelectedEvent = JSON.stringify({...this.selectedEvent})
        this.$swal({icon: "success", title: "Fecha actualizada correctamente"})
        .finally(() => (this.closeOnClick=true));
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error en la actualización", e.message);
      })
      .finally(() => (this.loading=false));
    },
    closeEventDate(){ 
      this.selectedOpen = false
      this.closeOnClick = true
      const jsonCompar = JSON.parse(this.backSelectedEvent)
      this.selectedEvent.description = jsonCompar.description
    },
    detectChangeDescription(){
      if(this.selectedOpen){
        const jsonCompar = JSON.parse(this.backSelectedEvent);
        if (this.selectedEvent.description !== jsonCompar.description) {
          this.closeOnClick= false
        }
        return this.selectedEvent.description === jsonCompar.description
      }

      return true
    },
    discardDate(thisEvent) {
      this.$swal({
        title: "¿Desea cancelar la fecha del evento?",
        text: "¡Ya no se encontrara disponible la fecha del evento!",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonText: "Si",
      }).then((confirmed) => {
        if (confirmed.isConfirmed) {
          this.$emit("discardDate", thisEvent)
        }
      });      
    },
    checkChangeX() {
      this.$refs.calendarEventDates.checkChange()
    },
    closeMiniEventInfo() {
      this.selectedOpen = false;
    },
    fixDate(date) {
      let x = date
      if(x){
        x = new Date(x)
        const day = x .getUTCDate();
        const month = x .getUTCMonth() + 1;
        const year = x .getUTCFullYear();
        const opcion = {weekday:"long", year:"numeric", month:"short", day:"numeric"}
        x = new Intl.DateTimeFormat('es-ES', opcion).format(new Date(year, month - 1, day));
      }
      return x;
    },
    getCurrentDate() {
      const date = new Date();
      
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      
      const hour = '12:00:00.000Z';
      this.currentDateX = `${year}-${month}-${day}T${hour}`;
    }
  }
}
</script>

<style scoped>
.date-style {
  max-width: 120px;
  text-align: center;
}

</style>