var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.vEntorno)?_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"1100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"grey darken-3","dark":""},on:{"click":_vm.loadAvailableTickets}},'v-btn',attrs,false),on),[_vm._v(" Vincular ")])]}}],null,false,865803250),model:{value:(_vm.addTicketDialog),callback:function ($$v) {_vm.addTicketDialog=$$v},expression:"addTicketDialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"grey darken-4"},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Tickets disponibles")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.addTicketDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('div',[_c('v-row',[_c('v-spacer'),_c('v-col',{staticStyle:{"padding":"20px 20px 20px 20px"},attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-2",staticStyle:{"padding-top":"10px"},attrs:{"fixed-header":"","headers":_vm.ticketsHeader,"items":_vm.listTicketsAvailable,"items-per-page":5,"search":_vm.search,"loading":_vm.loadingtickets,"no-data-text":"No hay boletos disponibles. Contacte a soporte para que se habiliten boletos"},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[_vm._v(" $"+_vm._s(item.price)+" ")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-switch',{attrs:{"inset":"","color":"success"},on:{"change":function($event){return _vm.changeSwitch(item, index)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)]}}],null,true)})],1),_c('v-card-actions',{staticClass:"pa-0 px-7 pb-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","disabled":_vm.listTicketsAvailable.length === 0 || _vm.newTicketsAdded.length === 0,"color":"orange darken-3"},on:{"click":function($event){return _vm.addTicketsSelect()}}},[_vm._v(" Vincular ")]),_c('v-btn',{attrs:{"color":"white"},on:{"click":function($event){_vm.addTicketDialog = false}}},[_vm._v(" Cancelar ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }