<template>
  <v-card>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <table style="width: 100%">
            <tr>
              <td style="width: 50%" align="right">
                <span class="text-h5 font-weight-bold">Tipo de evento: </span>
              </td>
              <td style="width: 50%">
                &nbsp;
                <span class="text-h5">{{
                  GetEventsConfig(eventDetails.event_config)
                }}</span>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
      <v-tabs class="mb-4" v-model="tab" color="orange darken-4">
        <v-tab v-for="item in languages" :key="'tab-lenguages-' + item.name">
          {{ item.name }}
        </v-tab>
      </v-tabs>
      <v-form>
        <v-row>
          <v-col cols="8" class="d-flex flex-column pb-0 mb-0">
            <v-text-field
              label="Nombre del evento*"
              placeholder="Escribe un nombre de evento"
              v-model="eventDetails.name.ES"
              persistent-hint
              outlined
              clearable
              v-if="tab == 0"
            ></v-text-field>
            <v-text-field
              label="Nombre del evento*"
              placeholder="Escribe un nombre de evento"
              v-model="eventDetails.name.EN"
              persistent-hint
              outlined
              clearable
              v-if="tab == 1"
            ></v-text-field>
            <v-text-field
              class="mb-3"
              v-model="currenIdPlace.eventplace"
              :hint="`${currenIdPlace.city || ''}, ${
                currenIdPlace.state || ''
              }`"
              label="Sitio seleccionado*"
              disabled
              persistent-hint
              outlined
            ></v-text-field>
            <v-select
              v-model="eventDetails.category"
              :items="eventTypeList"
              item-text="name"
              item-value="id"
              label="Categoria del evento*"
              outlined
              :disabled="tab == 1"
            ></v-select>
          </v-col>
          <v-col cols="4" class="d-flex flex-column align-center pt-0">
            <div class="d-flex align-center">
              <v-subheader class="font-weight-bold"
                >Imagen de portada*</v-subheader
              >
              <v-tooltip top color="grey darken-4" content-class="white--text">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    v-bind="attrs"
                    v-on="on"
                    dark
                    v-show="imgTemp != null"
                    color="blue-grey darken-3"
                    @click="imgTemp = null"
                  >
                    <v-icon size="20px"> mdi-restore </v-icon>
                  </v-btn>
                </template>
                <span>Restaurar imagen</span>
              </v-tooltip>
            </div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-img
                  v-bind="attrs"
                  v-on="on"
                  max-width="340px"
                  min-width="340px"
                  max-height="200px"
                  min-height="200px"
                  :src="
                    !eventDetails.img && !imgTemp
                      ? require('../../assets/sin-foto.jpg')
                      : imgTemp
                      ? imgTemp
                      : $root.pathevens + eventDetails.img
                  "
                  :class="eventDetails.img || imgTemp ? 'box' : 'box without'"
                  @click="UploadImg(MyEvent)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0 black"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="white"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </template>
              <span>Subir / Cambiar Portada </span>
            </v-tooltip>
            <v-file-input
              ref="fileup"
              type="file"
              id="coverpicker"
              accept=".png, .jpg, .webp, .jpeg"
              v-model="file"
              @change="readURL()"
              style="display: none"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex flex-column pt-0 mt-0">
            <v-subheader class="font-weight-bold">Descripción*</v-subheader>
            <v-tiptap
              v-model="eventDetails.acercade.ES"
              v-if="MyEvent.acercade"
            >
            </v-tiptap>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-divider class="black" />
    <v-card-actions>
      <v-spacer />
      <v-btn class="blue-grey darken-3" dark @click="back()">
        <v-icon left>mdi-arrow-left-bold</v-icon>
        Regresar
      </v-btn>
      <v-btn class="green darken-2" dark @click="saveEventDetails()">
        <v-icon left>mdi-check-bold</v-icon>
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "EventDetails",
  props: ["MyEvent", "currenIdPlace"],
  data() {
    return {
      tab: null,
      languages: [
        { id: "ES", name: "Español" },
        { id: "EN", name: "Inglés" },
      ],
      eventDetails: this.MyEvent,
      selectedPlace: null,
      eventTypeList: [],
      file: null,
      imgTemp: null,
    };
  },
  mounted() {
    this.eventTypeList = [...this.$store.state.catalogs.typeSites];
  },
  watch: {},
  methods: {
    back() {
      this.$router.push({ name: "Events" });
    },
    GetEventsConfig(event_config) {
      let configurations = { ...this.$store.state.catalogs.event_config_desc };
      return configurations[event_config] || "Sin configurar";
    },
    GetEventCategory(id_category) {
      let res = this.eventTypeList.find((elem) => elem.id === id_category);
      return res.name;
    },
    async UploadImg() {
      // this.$refs.fileup.$el.querySelector("input").click();
      document.querySelector("#coverpicker").click();
    },
    readURL() {
      let self = this;

      if (this.file) {
        if (
          this.file.type !== "image/png" &&
          this.file.type !== "image/jpeg" &&
          this.file.type !== "image/jpg" &&
          this.file.type !== "image/webp"
        ) {
          this.imgTemp = null;
          return this.$root.swalAlert(
            "error",
            "Archivo no permitido",
            "Sólo se admiten archivos JPG, JPEG, PNG o WEBP"
          );
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          self.imgTemp = e.target.result;
        };
        reader.readAsDataURL(this.file);
      }
    },
    saveEventDetails() {
      this.$root.post("/Admin/updateGeneralEventInfo", {
        id_event: this.eventDetails.id_event,
        name: this.eventDetails.name.ES,
        category: this.eventDetails.category,
        img: this.eventDetails.img,
        updateImage: this.imgTemp,
        acercade: this.eventDetails.acercade,
      })
      .then((response) => {
        let info = response.data;
        if(info.success === "FALSE"){
          return this.$root.swalAlert("error", "Error al guardar los cambios", info.Msg);
        }
        this.imgTemp = null
        this.eventDetails.img = info.Data.img
        this.$root.swalAlert("success", "Cambios guardados correctamente");
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error al guardar los cambios", e.message);
      });
    },
  },
};
</script>

<style scoped>
.box {
  position: relative;
  border-radius: 5px;
  width: 340px;
  height: 200px;
  background: #fff;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  border: 2px solid #3f414a;
  background-size: cover;
  background-position: 100% 100%;
}
.without {
  border: 0px solid #3f414a !important;
  box-shadow: 0 0 0px rgb(0 0 0 / 10%) !important;
}
</style>
