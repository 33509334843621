<template>
  <div v-if="vEntorno">
    <v-row class="pb-8">
      <v-col cols="6">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="MyTitle">Galeria</div>
          </template>
          <span>Cada subcategoría debe contener al menos una imagen en su galería</span>
        </v-tooltip>
      </v-col>
      <v-col cols="6" class="d-flex justify-end align-start">
        <div class="new-bottom">
          <v-btn small color="grey darken-3" dark :disabled="ImagesTemp.length >= 6" @click="UploadImg()">
              Agregar
          </v-btn>
          <div class="txtColor">
            <strong>Máximo 6 imágenes</strong>
          </div>
        </div>
      </v-col>
    </v-row>

    <draggable
      class="items-list"
      v-if="vEntorno.Subevents[index]"
      v-model="ImagesTemp"
      style="display: flex; flex-flow: row wrap; justify-content: space-evenly"
      draggable=".itemDragImg">
      <div
        v-for="(item, index) in ImagesTemp"
        :key="'ShortImg-' + index"
        style="padding: 5px; position: relative;"
        class="itemDragImg pointman" >
        <v-img
          :src="$root.pathgallery+item.name"
          :lazy-src="$root.pathgallery+item.name"
          aspect-ratio="1"
          class="grey lighten-2 igmPrn"
          height="150"
          width="150"
          style="border-radius: 10px; display: flex; align-items:end" >
          <div v-if="item.coverimage === 1">
            <v-chip
            class="ma-1"
            color="amber darken-4"
            text-color="white"
            small >
              Principal
            <v-icon right>
             mdi-star
            </v-icon>
          </v-chip>
          </div>
          

          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <div class="mx-1 my-1" style="position: absolute; top: -15px; right: -15px;">
          <v-btn
            class="mx-2"
            fab
            small
            dark
            color="error"
            @click="confirmDeleteImage(item, index)">
            <v-icon dark> mdi-delete </v-icon>
          </v-btn>
        </div>
        
      </div>
    </draggable>
    <input ref="uploader" class="d-none" type="file" accept=".png, .jpg, .webp, .jpeg" @change="onChange()">
    <v-file-input
      ref="fileup"
      type="file"
      id="refreshImg"
      accept=".png, .jpg, .webp, .jpeg"
      v-model="files"
      @change="readURL"
      style="display: none"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";
import $ from "jquery";

export default {
  name: "shorgallery",
  components: {
    draggable,
  },
  data() {
    return {
      vEntorno:null,
      draggableImg: [],
      files: null,
      flagImgEdit: false,
      ImagesTemp: [],
      galleryImgTemp: null
    };
  },
  props:["index"],
  mounted(){
    this.vEntorno = this.$vEntorno
    if(this.vEntorno.Subevents[this.index]) {
      this.ImagesTemp = [...this.vEntorno.Subevents[this.index].img];
    }
  },
  methods: {
    readURL() {
      let self = this;
      
      if (this.files) {
        if ( this.files.type !== "image/png" && this.files.type !== "image/jpeg" && this.files.type !== "image/jpg" && this.files.type !== "image/webp" ) {
          this.galleryImgTemp = null
          return this.$root.swalAlert("error", "Archivo no permitido", "Sólo se admiten archivos JPG, JPEG, PNG o WEBP");
        }
        
        const reader = new FileReader()
        reader.onload = (e) => {
          self.galleryImgTemp = e.target.result;
          self.addNewImage()
        }
        reader.readAsDataURL(this.files)
      }
    },
    UploadImg() {
      if (!this.flagImgEdit) {
        $("#refreshImg").click();
      } else {
        this.flagImgEdit = false;
      }
    },
    addNewImage() {
      this.$root.post("/Admin/galleryConfig", 
        { 
          id_cataloge: this.$store.state.events.currentCatalogeSelected,
          deleteImg: false,
          coverimage: this.ImagesTemp.length === 0 ? 1 : 0,
          newImage: this.galleryImgTemp
        }
      )
      .then((response) => {
        let info = response.data;
        if(info.success == "TRUE") {
          const newImageG = info.Data[0];
          this.ImagesTemp = [
            ...this.ImagesTemp,
            {
              id_gallery: newImageG.id_gallery,
              coverimage: newImageG.coverimage,
              name: newImageG.name,
              id_category: newImageG.id_category
            }
          ]
          this.flagImgEdit = true;
          this.vEntorno.Subevents[this.index].img = this.ImagesTemp
          this.$set(this.$vEntorno, "Subevents", this.vEntorno.Subevents);
          this.$root.swalAlert("success", "Imagen añadida");
        } else {
          this.$root.swalAlert("error", info.Msg );
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", e.message );
      })
      .finally( () => {
        this.galleryImgTemp = null;
      });
    },
    confirmDeleteImage(myImage, index) {
      this.$swal({
        title: '¿Está seguro(a)?',
        text: "Una vez borrada la imagen no se podrá recuperar",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, estoy seguro(a)!'
      })
      .then(response => {
        if (response.isConfirmed) {
          this.DeleteImg(myImage, index);
        }
      });
    },
    DeleteImg(item, index) {
      this.$root.post("/Admin/galleryConfig", 
        { 
          id_gallery: item.id_gallery,
          deleteImg: true
        }
      )
      .then((response) => {
        let info = response.data;
        if(info.success == "TRUE") {
          this.ImagesTemp.splice(index, 1);
          this.flagImgEdit = true;
          this.vEntorno.Subevents[this.index].img = this.ImagesTemp
          this.$set(this.$vEntorno, "Subevents", this.vEntorno.Subevents);
          this.$root.swalAlert("success", "Imagen eliminada");
        } else {
          this.$root.swalAlert("error", info.Msg );
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", e.message );
      });
    },
  },
};
</script>

<style scoped>
.new-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.MyTitle {
  text-align: left;
  font-weight: 800;
  color: #6e6e6e;
}
.txtColor {
  position: absolute;
  width: max-content;
  bottom: calc(0% - 22px);
  padding: 0px;
  text-align: center;
  color: red;
  font-size: 10px;
}
.btnNImg {
  width: 50%;
  text-align: center;
  padding-top: 40px;
}
</style>