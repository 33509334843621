var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"margin-top":"10px"},attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-n5"},[_c('div',{staticClass:"Mytab mx-auto"},_vm._l((_vm.configTabs),function(item,index){return _c('div',{staticClass:"tab",class:_vm.tab === index ? 'active' : '',on:{"click":function($event){_vm.tab = index}}},[_vm._v(" "+_vm._s(item)+" ")])}),0)])])],1),_c('v-container',{staticClass:"my-1 d-flex mb-6 flex-column"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.tab === 0 && _vm.vEntorno.Subevents.length === 0),expression:"tab === 0 && vEntorno.Subevents.length === 0"}],staticClass:"my-2 pa-2 ml-auto",attrs:{"color":"grey darken-4","dark":""},on:{"click":function($event){_vm.newSubcateDialog = true}}},'v-btn',attrs,false),on),[_vm._v(" configurar ")])]}}])},[_c('span',[_vm._v("Agregar configuración de boletos")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.tab === 1),expression:"tab === 1"}],staticClass:"my-2 pa-2 ml-auto",attrs:{"color":"grey darken-4","dark":""},on:{"click":function($event){_vm.addDatesDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" AGREGAR ")],1)]}}])},[_c('span',[_vm._v("Crear nuevas fechas")])]),(_vm.vEntorno && _vm.tab === 0)?_c('div',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.subcategoriesHeader,"items":_vm.vEntorno.Subevents,"search":_vm.search},on:{"click:row":_vm.openSubcategoryGroup},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.title[_vm.leng]))])])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.description[_vm.leng]))])])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-space-around",on:{"click":function($event){_vm.subcategoryFlag = false;}}},[_c('v-switch',{on:{"change":function($event){return _vm.categoryStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)]}}],null,true)})],1):_vm._e(),(_vm.vEntorno && _vm.tab === 1)?_c('div',[_c('calendarTool',{attrs:{"dates":_vm.datesPrepared},on:{"discardDate":_vm.cancelDate}})],1):_vm._e()],1),(_vm.dialogSubevents)?_c('subcategorieConfig',{attrs:{"subcateDialog":_vm.dialogSubevents,"subcategorie":_vm.configTabs[_vm.tab],"index":_vm.index},on:{"closeSubcategorieConfig":_vm.closeSubcateDialog}}):_vm._e(),(_vm.addDatesDialog)?_c('createDatesDialog',{attrs:{"id_event":_vm.MyEvent.id_event,"showDatesDialog":_vm.addDatesDialog},on:{"cancelProcess":_vm.cancelDates,"addNewDates":_vm.addNewDates}}):_vm._e(),(_vm.newSubcateDialog)?_c('newSubCategories',{attrs:{"subcategorie":'Evento con cupo limitado',"showDialog":_vm.newSubcateDialog,"subcateType":0},on:{"cancelCreation":function($event){_vm.newSubcateDialog = false},"rechargeSubcate":_vm.loadTicketConfiguration}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }