<template>
  <v-card flat>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" class="pa-0 px-4">
            <v-text-field
              v-model="url"
              append-icon="mdi-web"
              outlined
              clearable
              label="URL del redireccionamiento*"
            ></v-text-field>
            <!-- <v-btn class="light-blue darken-3" dark>
              <v-icon left>mdi-web</v-icon>
              Preview</v-btn> -->
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col cols="12">
            <v-card elevation="2" >
              <v-toolbar class="light-blue darken-3" dark>
                <v-toolbar-items>
                  <v-icon>web-check</v-icon>
                </v-toolbar-items>
                <v-toolbar-title>
                  <span>Vista de URL</span>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text class="pa-0">
                <iframe :src="url" height="400px" width="100%" frameborder="0"></iframe>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
      </v-container>
    </v-card-text>
    <v-divider class="black"></v-divider>
    <v-card-actions>
      <v-spacer />
      <v-btn class="blue-grey darken-3" dark @click="back()">
        <v-icon left>mdi-arrow-left-bold</v-icon>
        Regresar
      </v-btn>
      <v-btn
        class="green darken-2"
        dark
        :loading="btnLoading"
        @click="saveUrlEvent()"
      >
        <v-icon left>mdi-check-bold</v-icon>
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "EventType1",
  data() {
    return {
      url: null,
      eventDetails: null,
      btnLoading: false,
    };
  },
  props: ["MyEvent"],
  mounted() {
    this.eventDetails = this.MyEvent;
    this.url = Object.assign({}, this.MyEvent).url;
  },
  methods: {
    back() {
      this.$router.push({ name: "Events" });
    },
    saveUrlEvent() {
      this.btnLoading = true;
      this.$root
        .post("/Admin/updateEventURL", {
          id_event: this.eventDetails.id_event,
          newUrl: this.url,
        })
        .then((response) => {
          const info = response.data;
          if (info.success === "FALSE") {
            return this.$root.swalAlert(
              "error",
              "Error al guardar la URL del evento",
              info.Msg
            );
          }
          this.url = info.Data[0].url;
          this.$emit("updateEvent");
          this.$root.swalAlert("success", "Cambio guardado correctamente");
        })
        .catch((e) => {
          this.$root.swalAlert(
            "error",
            "Error al guardar la URL del evento",
            e.message
          );
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>
