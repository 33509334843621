import { render, staticRenderFns } from "./mapPreview.vue?vue&type=template&id=ffb020c4&scoped=true&"
import script from "./mapPreview.vue?vue&type=script&lang=js&"
export * from "./mapPreview.vue?vue&type=script&lang=js&"
import style0 from "./mapPreview.vue?vue&type=style&index=0&id=ffb020c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffb020c4",
  null
  
)

export default component.exports