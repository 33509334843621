<template>
  <div
    class="base"
    :style="[
      !backColor
        ? { 'background-color': 'transparent' }
        : { 'background-color': backColor },
    ]"
  >
    <div align="center">
      <div class="loading readaption">
        <div class="circulo2"></div>
        <div class="circulo3"></div>
        <div class="circulo4"></div>
      </div>
      <div style="color: white; font-size: 20px">
        {{ vEntorno.textloading }}
      </div>
    </div>

    <div class="fondo"></div>
  </div>
</template>

<script>
export default {
  name: "loadinAnimation",
  props: ["backColor"],
  data() {
    return {
      vEntorno: null,
    };
  },
  created() {
    this.vEntorno = this.$vEntorno;
  },
  mounted() {
    this.vEntorno = this.$vEntorno;
  },
};
</script>

<style scoped>
.base {
  position: absolute;
  z-index: 99;
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  height: 100%;
  transition: all 0.5s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  z-index: 999;
  position: relative;
  transition: all 0.5s ease;
  height: 300px;
  width: 300px;
  transform: rotate(0deg);
  animation-name: animationRotate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}
@keyframes animationRotate {
  0% {
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading .circulo2 {
  position: absolute;
  top: calc(50% + 20px);
  left: calc(50% - 90px);
  height: 60px;
  width: 60px;
  background: rgb(240, 0, 0);
  clip-path: circle(30px at 50% 50%);
  animation-name: animation2;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
}
@keyframes animation2 {
  0% {
    clip-path: circle(30px at 50% 50%);
  }
  100% {
    clip-path: circle(20px at 50% 50%);
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    background: rgb(197, 211, 6);
  }
}
.loading .circulo3 {
  position: absolute;
  top: calc(50% + 20px);
  left: calc(50% + 30px);
  height: 60px;
  width: 60px;
  background: rgb(17, 150, 239);
  clip-path: circle(30px at 50% 50%);
  animation-name: animation3;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
}
@keyframes animation3 {
  0% {
    clip-path: circle(30px at 50% 50%);
  }
  100% {
    clip-path: circle(20px at 50% 50%);
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    background: rgb(240, 0, 0);
  }
}
.loading .circulo4 {
  position: absolute;
  top: calc(0% + 40px);
  left: calc(50% - 30px);
  height: 60px;
  width: 60px;
  background: rgb(197, 211, 6);
  clip-path: circle(30px at 50% 50%);
  animation-name: animation4;
  animation-duration: 1s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}
@keyframes animation4 {
  0% {
    clip-path: circle(30px at 50% 50%);
  }
  100% {
    clip-path: circle(20px at 50% 50%);
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    background: rgb(17, 150, 239);
  }
}
.readaption{
    zoom: 60%;
  }
@media (max-width: 700px) {
  .readaption{
    zoom: 30%;
  }
}
</style>