var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"AvailableTicketsMapTableForm"},[_c('v-data-table',{staticClass:"elevation-0 transparent mt-3",attrs:{"headers":_vm.headers,"items":_vm.sectionsCopy,"items-per-page":5,"no-data-text":"No hay secciones habilitadas"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id_section,on:{"mouseover":function($event){return _vm.hoverSection(item)},"mouseleave":function($event){return _vm.hoverSection(false)}}},[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',{staticClass:"pa-0 text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',{attrs:{"color":item.showseat !== 1 ? 'amber lighten-3' : 'light-blue lighten-3'}},[_c('v-icon',_vm._g(_vm._b({attrs:{"color":"light-blue darken-3","dark":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.showseat === 1 ? 'mdi-seat' : 'mdi-human-queue')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.showseat === 1 ? 'Sección con asientos' : 'Sección sin asientos'))])])],1),_c('td',{staticStyle:{"max-width":"150px"}},[_c('v-select',{attrs:{"dense":"","disabled":_vm.lockMap,"items":_vm.availableTickets,"item-text":function (ref) {
	var alias = ref.alias;
	var price = ref.price;

	return (alias + " - $" + price);
},"item-value":"id_ticket","hide-selected":"","rules":[ _vm.rules.ticketRequired ],"no-data-text":"Favor de contactar a soporte para activar boletos.","placeholder":"Selección Boleto"},model:{value:(item.id_ticket),callback:function ($$v) {_vm.$set(item, "id_ticket", $$v)},expression:"item.id_ticket"}})],1),_c('td',{staticStyle:{"max-width":"90px"}},[_c('v-text-field',{attrs:{"dense":"","type":"number","disabled":item.showseat === 1 || _vm.lockMap,"rules":[ _vm.rules.maxRequired ]},on:{"change":function($event){return _vm.checkValue(item)}},model:{value:(item.max_seat),callback:function ($$v) {_vm.$set(item, "max_seat", $$v)},expression:"item.max_seat"}})],1)])}),0)]}},{key:"header.max",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-icon',[_vm._v("mdi-account-supervisor")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }