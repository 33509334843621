<template>
  <div>
    <v-dialog
      persistent
      no-click-animation
      v-model="mapConfig"
      fullscreen
      transition="dialog-bottom-transition">

      <template v-slot:activator="{ on, attrs }">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-container 
              :class="`elevation-${hover ? 2 : 0}`"
              fluid
              style="cursor: pointer;"
              class="pa-0 px-6 py-3 my-1  
                amber accent-4 black--text text-uppercase font-weight-bold overline 
                d-flex justify-space-between align-center"          
              v-bind="attrs"
              v-on="on">
              <div style="font-size: 13px;">
                Ir a configuración de Mapa (Template)
              </div>
              <v-icon size="40" :color="hover ? 'white' : 'amber accent-2'">
                mdi-menu-right
              </v-icon>
            </v-container>
          </template>
        </v-hover>
      </template>

      <v-card>
        <v-toolbar
          dark
          color="indigo lighten-1">
          <v-btn
            icon
            dark
            @click="closeMapConfig">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Configuración de Mapa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              :disabled="lockChanges || !id_loyaut_Selec"
              @click="saveRelationTickets">
              Guardar Cambios
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div class="text-center overline indigo lighten-5" style="font-size: 14px !important;">
          Templates
        </div>

        <div v-if="loyauts.length > 0">
          <v-card-text class="px-5 py-4 d-flex justify-center" style="height: 22vh;">
            <v-card
              v-for="(item, index) in loyauts" 
              :disabled="loadingMap || lockChanges"
              @click="getMapPreview(item)"
              :key="index"
              max-width="200"
              height="20vh"
              hover
              :elevation="item.id_loyaut === id_loyaut_Selec ? '15' : '1'"
              class="animate__animated animate__flipInX mx-3">
              <div
                style="
                  height: 120px;
                  padding-top: 0px;
                  padding-bottom: 15px;
                  position: relative;" >
                <img :src="$root.pathLoyaut + item.img" />
              </div>
              <v-card-text
                class="text-center black--text">
                <strong>{{ item.name }}</strong>
              </v-card-text>
            </v-card>
          </v-card-text>
          <div class="mx-auto text-center">
            <v-btn icon color="indigo lighten-1">
              <v-icon>
                mdi-menu-down
              </v-icon>
            </v-btn>
          </div>
        </div>

        <v-container v-else
          class="subtitle-2 text-center mt-2 text-container d-flex justify-center align-center">
          No hay templates disponibles. <br> Por favor, contacte al soporte para solicitar la activación de una.
        </v-container>

        <v-card elevation="0" class="my-3">
          <div class="text-center overline indigo lighten-5" style="font-size: 14px !important;">
            Prevista y Relación de Boletos
          </div>
          <v-card-text class="pa-0 mt-3">
            <v-row class="px-1 mx-auto" style="height: 57vh; max-height: 57vh; overflow: hidden;">
              <v-col class="grey lighten-4 pa-0" cols="8" style="height: 100%;">
                <mapPreview 
                  :preview="currentPlace.map" 
                  :MapSvg="MapSvg" 
                  :sectionHover="sectionHovered"
                  :loadingMap="loadingMap"/>
              </v-col>
              <v-col class="indigo lighten-5 pa-0 px-3" cols="4" style="height: 100% !important; overflow: auto;">
                <availableTicketsTableMap 
                  ref="availableTicketsTable"
                  :sections="assignSections" 
                  :availableTickets="listTicketsAvailable"
                  :lockMap="lockChanges"
                  @verifyTickets="verifyTickets"
                  @hoverSection="hoverSection"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>

    <v-dialog
      persistent
      no-click-animation
      v-model="datesConfig"
      fullscreen
      transition="dialog-bottom-transition">

      <template v-slot:activator="{ on, attrs }">
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-container 
              fluid
              :class="`elevation-${hover ? 2 : 0}`"
              style="cursor: pointer;"
              class="pa-0 px-6 py-3 my-0  
                amber accent-4 black--text text-uppercase font-weight-bold overline 
                d-flex justify-space-between align-center"          
              v-bind="attrs"
              v-on="on">
              <div style="font-size: 13px;">
                Ir a configuración de fechas del evento
              </div>
              <v-icon size="40" :color="hover ? 'white' : 'amber accent-2'">
                mdi-menu-right
              </v-icon>
            </v-container>
          </template>
        </v-hover>
      </template>

      <v-card>
        <v-toolbar
          dark
          color="indigo lighten-1">
          <v-btn
            icon
            dark
            @click="closeDateConfig">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Configuración de fechas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="addDates">
              Añadir Fechas
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <datesSettingSection ref="datesSettingsSectionX" 
          class="px-6"
          :datesCharged="eventDates" 
          :maxCapacity="Number(eventCapacityValue)"
        />
        
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import mapPreview from '../Tools/mapPreview.vue'
import availableTicketsTableMap from '../Tools/AvailableTicketsMapTable.vue'
import datesSettingSection from "../Tools/datesSettingsSection.vue"

export default {
  name: 'type5Configure',
  components: {
    mapPreview,
    availableTicketsTableMap,
    datesSettingSection
  },
  data () {
    return {
      configTabs: [
        "SELECCIÓN DE MAPA (TEMPLATE)",
        "CONFIGURACIÓN DE ASIENTOS (SECCIONES)",
        "CONFIGURACIÓN DE FECHAS"
      ],
      mapConfig: false,

      loyauts: [],
      listTicketsAvailable: [],
      currentPlace: {},
      eventCapacityValue: 0,

      id_loyaut_Selec: false,
      loyautUsing: "",

      MapSvg: null,
      assignSections: [],
      activeDates: 0,

      sectionHovered: false,
      loadingMap: false,
      lockChanges: false,

      datesConfig: false,
      eventDates: []
    }
  },
  watch: {
    mapConfig: function (value) {
      this.currentPlace = this.$store.getters['catalogs/getMapPlace'](this.$store.state.events.eventSelected.id_place);
      if(value) this.getLoyauts();
    },
    datesConfig: function (value) {
      if(value) this.loadEventDates();
    }
  },
  methods: {
    ...mapGetters({
      getMapPlaceX: "catalogs/getMapPlace",
    }
    ),
    getLoyauts() {
      this.loyauts = []
      this.id_loyaut_Selec = false
      this.$root
      .post("/Admin/getLoyauts", { 
        id_place: this.$store.state.events.eventSelected.id_place,
        id_event: this.$store.state.events.eventSelected.id_event
      })
      .then((response) => {
        const info = response.data;
        if (info.success === "TRUE") {
          this.loyauts = info.Data["myLoyauts"]
          this.activeDates = info.Data["activeDates"]
          this.checkActiveLoyaut(info.Data["myLoyauts"])
          this.loadAvailableTickets()
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error de carga", e.message);
      });
    },
    loadAvailableTickets() {
      this.listTicketsAvailable = []
      this.$root.post("/Admin/availableTickets", {
        id_place: this.$store.state.events.eventSelected.id_place,
        id_event: this.$store.state.events.eventSelected.id_event
      })
      .then((response) => {
        let info = response.data;
        if(info.success == 'TRUE') {
          this.listTicketsAvailable = info.Data
        } else {
           this.listTicketsAvailable = []
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error de carga boletos", e.message);
      })
      .finally(() => {
      });
    },
    getMapPreview(loyaut) {
      const { id_loyaut } = loyaut
      if(id_loyaut === this.id_loyaut_Selec) return

      this.loadingMap = true;
      this.$root.post("/Admin/getMapPreview", { 
        id_loyaut,        
        id_event: this.$store.state.events.eventSelected.id_event
      })
      .then((response) => {
        let info = response.data;
        if(info.success == 'TRUE') {
          this.MapSvg = info.Data
          this.id_loyaut_Selec = id_loyaut
          this.prepareSections(info.Data)
        } else {
          this.id_loyaut_Selec = null
          this.listTicketsAvailable = []
          this.$root.swalAlert("warning", "", info.Msg);
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", "Error en carga de Mapa", e.message);
      })
      .finally(() => {
        this.loadingMap = false;
        this.$refs.availableTicketsTable.resetTableValidation()
      });
    },
    prepareSections(sections) {
      this.assignSections = []
      
      sections.forEach( ({ id_section, section, id_ticket, max_seat, showseat }) => {
        this.assignSections = [
          ...this.assignSections,
          {
            id_section,
            name: section,
            id_ticket,
            max_seat,
            showseat
          }
        ]
      });
    },
    closeMapConfig() {
      this.assignSections = []
      this.listTicketsAvailable = []
      this.MapSvg = null
      this.id_loyaut_Selec = false
      this.mapConfig = false
      this.$refs.availableTicketsTable.resetTable()
    },
    closeDateConfig() {
      this.$refs.datesSettingsSectionX.setCaledarToday()
      this.datesConfig = false
    },
    hoverSection(section) {
      this.sectionHovered = section
    },
    checkActiveLoyaut(loyauts) {
      const found = loyauts.find(({ selected }) => selected === true);
      if(found) {
        this.getMapPreview(found)
        this.loyautUsing = {...found}
        this.lockChanges = !!this.$store.state.events.event.activo || this.activeDates > 0
      }
      if(!found) {
        this.lockChanges = false
        this.loyautUsing = ""
      }
    },
    saveRelationTickets() {
      if(!this.id_loyaut_Selec) return this.$root.swalAlert("warning", "Favor de seleccionar un Template");
      this.$refs.availableTicketsTable.confirmTicketRelation();
    },
    verifyTickets(value) {
      value.forEach(({ id_section, max_seat, id_ticket, id_event }) => {
        this.MapSvg.forEach(section => {
          if(section.id_section === id_section) {            
            section.id_event = this.$store.state.events.eventSelected.id_event,
            section.max_seat = max_seat
            section.id_ticket = id_ticket
            section.sec_seat = id_section
          }
        })
      })
      
      const found = this.loyauts.find(({ id_loyaut }) => id_loyaut === this.id_loyaut_Selec);
      this.loyautUsing = {...found}
      this.changeEventLoyaut()
    },
    addDates() {
      this.$refs.datesSettingsSectionX.addNewDates();
    },
    loadEventDates() {
      this.eventDates = [];

      this.$root
      .post("/Admin/getEventDates", { 
        event: this.$store.state.events.event 
      })
      .then((response) => {
        const info = response.data;
        if (info.success === "TRUE") {
          this.eventDates = info.dates;
        } else {
          this.eventDates = [];
        }
      })
      .catch((e) => {
        this.$root.swalAlert("error", "Error de carga de fechas", e.message);
      })
      .finally(() => {
        this.getEventCapacity()
        this.$refs.datesSettingsSectionX.prepareDates(this.eventDates, true)
      });
    },
    changeEventLoyaut() {
      this.$root
      .post("/Admin/changeEventLoyaut", { 
        id_event: this.$store.state.events.eventSelected.id_event,
        id_loyaut: this.id_loyaut_Selec,
        relationTickets: this.MapSvg
      })
      .then((response) => {
        const info = response.data;
        if (info.success === "TRUE") {
          this.getEventCapacity()
          this.$root.swalAlert("success", "Secciones actualizadas")
        } else {
          this.$root.swalAlert("error", info.Msg)
        }
      })
      .catch((e) => {
        this.$root.swalAlert("error", "Error de actualización", e.message);
      })
    },
    getEventCapacity() {
      this.$root
      .post("/Admin/getTotalCapacity", { 
        id_event: this.$store.state.events.eventSelected.id_event
      })
      .then((response) => {
        const info = response.data;
        if (info.success === "TRUE") {
          this.eventCapacityValue = info.Data[0].currentMax
        } else {
          this.eventCapacityValue = 0
          if(info.Msg !== "Sin Registros") this.$root.swalAlert("error", info.Msg)
        }
      })
      .catch((e) => {
        this.eventCapacityValue = 0
        this.$root.swalAlert("error", "Error de actualización", e.message);
      })
    }
  }
}
</script>

<style scoped>
  .Mytab {
    font-size: 12px;
    display: flex;
    width: 100%;
    border-bottom: 2px solid #252525;
  }
  .Mytab .tab {
    padding: 8px 10px;
    border-radius: 5px 5px 0px 0px;
    font-weight: bold;
    letter-spacing: 2px;
  }
  .Mytab .tab:hover:not(.active) {
    background: rgb(73, 69, 69);
    color: black;
    cursor: pointer;
    color: white;
  }
  .Mytab .active {
    background: #252525;
    color: white;
  }
  .text-container {
    min-height: 25vh;
  }
</style>