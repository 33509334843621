<template>
  <v-dialog v-model="showDialog" persistent no-click-animation max-width="650">
    <v-card>
      <v-toolbar dark>
        Nuevo grupo de boletos para : <b>{{ subcategorie }}</b>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="$emit('cancelCreation')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-tabs v-model="vtab" color="grey darken-4" background-color="white">
          <v-tab>Español</v-tab>
          <v-tab>Ingles</v-tab>
        </v-tabs>
        <v-form ref="nameForm">
          <v-container>
            <v-col>
              <div class="MyTitle">Datos Generales del Grupo</div>
              <v-row class="mt-3">
                <v-col cols="12" v-if="vtab == 0">
                  <v-text-field
                    label="Nombre de la categoria ESPAÑOL"
                    placeholder="Escribe un nombre de la categoria en ESPAÑOL"
                    v-model="descriptionGeneral.nameEs"
                    hint="Ejemplo: Parador Chichen Itza"
                    persistent-hint
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" v-if="vtab == 1">
                  <v-text-field
                    label="Nombre de la categoria INGLÉS"
                    placeholder="Escribe un nombre de la categoria en INGLÉS"
                    v-model="descriptionGeneral.nameEn"
                    hint="Ejemplo: Parador Chichen Itza"
                    persistent-hint
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col cols="12" v-if="vtab == 0">
                  <v-textarea
                    v-model="descriptionGeneral.descriptionGeneralEs"
                    outlined
                    :counter="250"
                    :rules="[validDescription]"
                    label="Descripción de la categoria ESPAÑOL"
                    height="100"
                    no-resize
                  />
                </v-col>
                <v-col cols="12" v-if="vtab == 1">
                  <v-textarea
                    v-model="descriptionGeneral.descriptionGeneralEn"
                    outlined
                    :counter="250"
                    :rules="[validDescription]"
                    label="Descripción de la categoria INGLÉS"
                    height="100"
                    no-resize
                    outline
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-0 px-7 pb-5">
        <v-btn color="error" @click="$emit('cancelCreation')"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :loading="loadingBtn"
          @click="saveSubCategorie()"
        >
          Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "newSubCategories",
  props: ["subcategorie", "showDialog", "subcateType"],
  data() {
    return {
      vtab: 0,
      descriptionGeneral: {
        nameEs: "",
        descriptionGeneralEs: "",
        nameEn: "",
        descriptionGeneralEn: "",
      },
      loadingBtn: false,
    };
  },
  methods: {
    validDescription(value) {
      // if (!!this.descriptionGeneral.descriptionGeneralEs !== !!this.descriptionGeneral.descriptionGeneralEn) {
      //     return "Si se va a añadir descripción, se deben llenar en AMBOS IDIOMAS";
      // }

      if (value && value.length >= 250) {
        return "La descripción debe tener menos de 250 caracteres";
      } else {
        return true;
      }
    },
    saveSubCategorie() {
      if (this.$refs.nameForm.validate()) {
        if (
          this.descriptionGeneral.nameEs.length === 0 &&
          this.descriptionGeneral.nameEn.length === 0
        ) {
          this.$root.swalAlert(
            "info",
            "Creción de subcategoria",
            "Debe ingresar al menos un nombre"
          );
          return;
        }
        this.loadingBtn = true;

        this.$root
          .post("/Admin/createNewSubcategory", {
            id_event: this.$store.state.events.eventSelected.id_event,
            newSubcategorie: this.descriptionGeneral,
            subcateType: this.subcateType,
          })
          .then((response) => {
            const info = response.data;

            if (info.success === "TRUE") {
              this.$emit("rechargeSubcate", {
                id_event: this.$store.state.events.eventSelected.id_event,
              });
              this.$root.swalAlert("success", "Subcategoría creada!");
              this.$emit("cancelCreation");
            } else {
              this.$root.swalAlert("error", info.Msg);
            }
          })
          .catch((e) => {
            this.$root.swalAlert("error", e.message);
          })
          .finally(() => {
            this.loadingBtn = false;
          });
      } else {
        this.$root.swalAlert("error", "¡Llene correctamente los campos!");
      }
    },
  },
};
</script>
