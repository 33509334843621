<template>
  <div style="padding-top: 10px" v-if="vEntorno">
    <v-row class="pb-3">
      <v-col cols="6">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="MyTitle">Especificaciones</div>
          </template>
          <span>Las especificaciones se mostrarán debajo de la descripción de la subcategoría</span>
        </v-tooltip>
      </v-col>
      <v-col cols="6" class="d-flex justify-end align-start">
        <v-btn small color="amber accent-4"
            class="mx-5" @click="updateSpecications" 
            :disabled="JSON.stringify(copyInfo) === originInfo">
          Guardar Cambios
        </v-btn >
        <div class="new-bottom">
          <v-btn small dark  color="grey darken-3" @click="newInfo" :disabled="copyInfo.length > 4" >
            Nuevo
          </v-btn>
          <div class="txtColor">
            <strong>Máximo 5 especificaciones</strong>
          </div>
        </div>
      </v-col>
    </v-row>
    
    <v-list dense style="padding: 0" v-if="copyInfo.length > 0">
      <v-list-item class="my-0 py-0 mb-n2">
        <v-list-item-icon class="my-auto text-center mx-0 mr-1">
          <span class="text-center overline"> ÍCONO </span>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="d-flex justify-space-around">
            <span class="text-center overline"> ESPAÑOL </span>
            <span class="text-center overline"> INGLÉS </span>                
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <div class="d-flex text-center overline">
            QUITAR
          </div>
        </v-list-item-action>
      </v-list-item>
      
      <v-divider class="pa-0 ma-0"></v-divider>

      <draggable
        v-model="copyInfo"
        draggable=".itemDragList" >
        <v-list-item
          v-for="(item, i) in copyInfo"
          :key="i"
          class="itemDragList"
          @click="openinfo(item ,i)" >
          <v-list-item-icon class="my-auto text-center">
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="d-flex justify-space-around">
              <span class="text-center">{{ item["ES"] }}</span>
              <span class="text-center">{{ item["EN"] }}</span>                
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <div class="d-flex text-center">
              <v-btn icon @click="deleteInfo(item, i)">
                <v-icon color="grey lighten-1">mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </draggable>
    </v-list>

    <v-dialog v-if="modifyInfo" v-model="modifyInfo" max-width="700">
      <v-toolbar color="grey darken-4" class="overline" style="font-size: 15px !important;" dark> Nueva especificación</v-toolbar>
      <v-card width="700" class="px-3 pt-8 pb-2">
        <v-card-text>
          <v-form ref="specifForm">
            <v-row>
              <v-col cols="2" >
                <v-menu 
                  offset-y
                  transition="slide-y-transition"            
                  rounded="lg">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          color="grey darken-3"
                          dark
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }" >
                        <v-icon>
                          {{ info.icon }}
                        </v-icon>
                        </v-btn>
                      </template>
                      <span>Ícono del texto</span>
                    </v-tooltip>
                  </template>
                  
                  <v-container 
                      class="d-flex flex-wrap justify-space-between white"
                      style="max-width: 250px;">
                    <v-icon class="px-2 py-3" 
                        size="30" v-for="(icon, index) in especifications" 
                        :key="index" 
                        @click="selectIcon( icon )">
                      {{ icon }}
                    </v-icon>
                  </v-container>            
                  <v-divider></v-divider>
                  <v-container class="white">
                    <div class="text-center overline select-none" @click="selectIcon( null )">No usar ícono</div>
                  </v-container>
                </v-menu>
              </v-col>
              <v-col cols="5" >
                <v-text-field
                  label="Texto en ESPAÑOL"
                  placeholder="Especificación"
                  v-model="info['ES']"
                  dense
                  hint="Ejemplo: horario de lunes a viernes"
                  persistent-hint
                  :rules="[ rules.required ]"
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  label="Texto en INGLÉS"
                  placeholder="Specification"
                  v-model="info['EN']"
                  dense
                  hint="Example: hours from Monday to Friday"
                  persistent-hint
                  :rules="[ rules.required ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="updateList(info)" color="amber accent-4">
            aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "specificationsSection",
  props:["index", "leng"],
  components: {
    draggable,
  },
  data() {
    return {
      vEntorno:null,
      originInfo: [],
      copyInfo: [],
      modifyInfo: false,
      info : {
        icon: null,
        "ES": null,
        "EN": null
      },

      draggableList: [],
      selectedItem: 1,
      especifications: [
        'mdi-account', "mdi-gnome", "mdi-paw", "mdi-shower",
        "mdi-clock", "mdi-horse", "mdi-calendar", "mdi-baby-carriage",
        "mdi-help", "mdi-ambulance", "mdi-airplane", "mdi-taxi",
        "mdi-account-multiple", "mdi-account-multiple-check", "mdi-minus", "mdi-minus-circle",
        "mdi-silverware-fork-knife", "mdi-alert-circle", "mdi-alarm", "mdi-calendar-multiple",
        "mdi-calendar-multiple-check", "mdi-calendar-clock", "mdi-card-account-details-outline", "mdi-chat",
      ],
      iconSelected: "mdi-calendar",

      deleteAction: false,
      newSpecific: false,
      rules: {
        required: v => !!v || 'Este campo es requerido'
      }
    };
  },
  mounted(){
    this.vEntorno = this.$vEntorno
    this.transformSpecifications()
  },
  methods: {
    transformSpecifications() {
      let copy = {...this.vEntorno.Subevents[this.index].info }
      let specifications = []
      for(let i = 0 ; i < copy['ES'].length; i++) {
        specifications = [
          ...specifications,
          {
            icon: copy['ES'][i].icon,
            'ES': copy['ES'][i].info,
            'EN': copy['EN'][i].info
          }
        ]
      }
      this.copyInfo = specifications;
      this.originInfo = JSON.stringify(specifications);
    },
    updateSpecications() {
      let transform = {
        "ES": [ ],
        "EN": [ ]
      }

      this.copyInfo.forEach( translate => {
        transform["ES"] = [
          ...transform["ES"],
          {
            icon: translate.icon,
            info: translate["ES"]
          }
        ]
        transform["EN"] = [
          ...transform["EN"],
          {
            icon: translate.icon,
            info: translate["EN"]
          }
        ]
      });
      
      this.$root.post("/Admin/updateSubcategorie", 
        { 
          id_event: this.$store.state.events.eventSelected.id_event,
          id_cataloge: this.$store.state.events.currentCatalogeSelected,
          typeUpdate: 2,
          specifications: transform
        }
      )
      .then((response) => {
        let info = response.data;

        if(info.success == "TRUE") {
          this.vEntorno.Subevents[this.index].info = transform;
          this.$set(this.$vEntorno, "Subevents", this.vEntorno.Subevents);

          this.transformSpecifications()

          this.$root.swalAlert("success", "Información actualizada");
        } else {
          this.$root.swalAlert("error", info.Msg );
        }
      })
      .catch(e => {
        this.$root.swalAlert("error", e.message );
      });
    },
    openinfo(item, i){
      if(!this.deleteAction) {        
        this.newSpecific = false
        this.info = {...item, i}
        this.modifyInfo = true;
      }
      this.deleteAction = false;
    },
    newInfo() {
      this.newSpecific = true
      this.info = {
        icon: null,
        "ES": null,
        "EN": null
      }
      this.modifyInfo = true;
    },
    selectIcon( newIcon ) {
      this.info.icon = newIcon;
    },
    updateList(item) {
      if(this.$refs.specifForm.validate()) {
        if(!this.newSpecific) {
          const update = {
            icon: item.icon,
            "ES": item['ES'],
            "EN": item['EN']
          }
    
          this.copyInfo[item.i] = update
        } else {
          this.copyInfo = [
            ...this.copyInfo,
            this.info
          ]
          this.newSpecific = false
        }
        this.modifyInfo = false;
      }
    },
    deleteInfo(item, index) {
      this.deleteAction = true;
      this.copyInfo.splice(index, 1)
    }
  },
};
</script>

<style scoped>
.new-bottom {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.select-none {
  cursor: pointer;
  border-radius: 5px;
}
.select-none:hover {
  box-shadow: 0px 0px 4px 1px grey;
}
.MyTitle {
  text-align: left;
  font-weight: 800;
  color: #6e6e6e;
  width: fit-content;
}
.txtColor {
  position: absolute;
  width: max-content;
  bottom: calc(0% - 22px);
  padding: 0px;
  text-align: center;
  color: red;
  font-size: 10px;
}
</style>